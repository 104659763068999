import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../include/Footer';
import Loader from '../../include/Loader';
import Menu from '../../include/Menu';
import Spinner from '../../include/Spinner';
import { Pagination, Search } from '../../Table';
import { Dropdown, Table } from "react-bootstrap";
import axios from 'axios';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { D_URL } from "../../baseurl";
import { duration } from 'moment';


const List = () => {

    useEffect(() => {

        if (localStorage.getItem("OM_Admin_ID") == null) {
            navigate("/om-outline-admin");
            return;
        }

        document.getElementById("page-loader").style.display = "none";
        var element = document.getElementById("page-container");
        element.classList.add("show");

        if (disable == true) {
            setEntity([]);
            getVideo();
        }
    }, [])

    const location = useLocation();
    const navigate = useNavigate();
    const [entity, setEntity] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);

    const [newVideo, setNewVideo] = useState({
        course_id: location.state._id
    })

    const videoData = useMemo(() => {
        let computedPlan = entity;
        if (search) {
            computedPlan = computedPlan.filter(
                (course) =>
                        course?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                        course?.guru_name?.toLowerCase()?.includes(search?.toLowerCase())
                );
        }
        setTotalItems(computedPlan?.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedPlan = computedPlan.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedPlan?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [entity, currentPage, search, sorting, limit])

    const getVideo = () => {
        const myurl = `${D_URL}/get-video-id-wise`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("course_id", newVideo.course_id);

        bodyFormData.append("page", currentPage);

        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('OM_Admin_ID') },
        })
            .then(async (response) => {
                console?.log("@@", response?.data);
                if (response?.data?.status) {
                    let data = await response?.data?.data?.map((e, i) => {
                        return { ...e, sr_no: i + 1 };
                    });
                    setEntity(data);
                    setDisable(false);
                } else {
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
                console?.log("Errors", error);
            });
    }

    const deleteEvent = (id, name) => {
        Swal.fire({
            title: "Delete",
            text: `Are you sure to want to delete ${name}`,
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then(async (res) => {
            if (res.isConfirmed) {
                const myurl = `${D_URL}/delete-video`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("video_id", id);

                axios({
                    method: "post",
                    url: myurl,
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('OM_Admin_ID') },
                })
                    .then(async (response) => {
                        console?.log("@@@", response?.data);
                        if (response?.data?.status) {
                            setDisable(true);
                            toast.success(response?.data?.message);
                            navigate("/courses-list")
                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };

    return (
        <>
            <Loader />

            <div
                id="page-container"
                className="fade page-sidebar-fixed page-header-fixed"
            >
                <Menu />

                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard">
                                <span className="basePath">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink to="/courses-list">
                                <span className="basePath">Courses</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item active currentPath">Videos</li>
                    </ol>
                    <h1 className="page-header">Videos Management</h1>
                    {disable == false ? (
                        <div
                            style={{
                                backgroundColor: "white",
                                padding: "20px",
                                borderRadius: "20px",
                                marginTop: "10px",
                            }}
                        >
                            <div className="row w-100">
                                <div className="mb-3 col-12 text-center">
                                    <button onClick={() => navigate('/add-video', { state: newVideo.course_id })} className="btn btn-primary mb-3" style={{ float: "right", float: "right", padding: "3px 20px", fontSize: "16px" }}>Add Video</button>
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-3 col-sm-3 col-12 mb-3">
                                            <div className="ml-0">
                                                <div className="d-flex">
                                                    <h5 className="mt-2 mr-1">Search: </h5>
                                                    <Search
                                                        onSearch={(value) => {
                                                            setSearch(value);
                                                            setCurrentPage(1);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "12px",
                                                    fontWeight: "300",
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                }}
                                                className="align-self-center"
                                            >
                                                <h5 className="mt-2 mr-1">Total Videos : {entity?.length} </h5>
                                            </div>
                                        </div>
                                        <div className="col d-flex justify-content-end mb-3">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "12px",
                                                    fontWeight: "300",
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                }}
                                                className="align-self-center"
                                            >
                                                <b>Rows per page :&nbsp;</b>
                                            </div>
                                            <div className="align-self-center">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="none"
                                                        id="dropdown-basic"
                                                        style={{
                                                            cursor: "auto",
                                                            backgroundColor: "white",
                                                            borderColor: "#d5dbe0",
                                                            paddingBottom: "3px",
                                                            paddingTop: "3px",
                                                        }}
                                                    >
                                                        {limit}&nbsp;<i className="fa fa-caret-down"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {limit !== 10 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(10);
                                                                    }}
                                                                >
                                                                    10
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 20 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(20);
                                                                    }}
                                                                >
                                                                    20
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 30 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(30);
                                                                    }}
                                                                >
                                                                    30
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 50 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(50);
                                                                    }}
                                                                >
                                                                    50
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr no</th>
                                                            <th>Video</th>
                                                            <th>Name</th>
                                                            <th>Guru Name</th>
                                                            <th>Duration</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {videoData?.map((e, i) => (
                                                            <tr key={e?._id}>
                                                                <>
                                                                    <td>{e?.sr_no}</td>
                                                                    <td>
                                                                        {<video src={e?.video_url} alt='' className='mx-2' style={{ width: "auto", height: "100px", objectFit: "cover", borderRadius: '10px' }} controls />}
                                                                    </td>
                                                                    <td>{e?.name}</td>
                                                                    <td>{e?.guru_name}</td>
                                                                    {/* <td>{(e?.video_duration / 60).toFixed(2)} min</td> */}
                                                                    <td>
                                                                        {`${Math.floor(e?.video_duration / 60)} min ${Math.floor(e?.video_duration % 60)} sec`}
                                                                    </td>
                                                                    <td>
                                                                        {/* <span className="text text-info" style={{ cursor: "pointer" }} onClick={() => navigate("/edit-video", { state: e })}><u>Edit</u></span><br /> */}
                                                                        <span className="text text-secondary" style={{ cursor: "pointer" }} onClick={() => deleteEvent(e._id, e.name)}><u>Delete</u></span>
                                                                    </td>
                                                                </>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                {videoData?.length === 0 ? (
                                                    <div className="row d-flex justify-content-center w-100">
                                                        <div className="mx-auto d-flex justify-content-center w-100">
                                                            <img
                                                                src="./assets/img/icon/no-location.png"
                                                                className="form-img__img-preview"
                                                                style={{ width: "100px", height: "100px" }}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                                        style={{
                                            overflowX: "auto",
                                        }}
                                    >
                                        <Pagination
                                            total={totalItems}
                                            itemsPerPage={limit}
                                            currentPage={currentPage}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </div>

                <Footer />
            </div>
        </>
    )
}

export default List