import React, { useEffect, useState } from "react";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { D_URL } from "../baseurl";

export default function EditGuruDetails() {
  const location = useLocation();
  var data = location.state;
  const navigate = useNavigate();

  const [disable, setDisable] = useState(true);
  console.log("~~~~~~~~~~", data);

  useEffect(() => {
    if (localStorage.getItem("OM_Admin_ID") == null) {
      navigate("/om-outline-admin");
      return;
    }
    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);
  const showWorkingHours=(days)=>{
    let workingHours="";
    for(var day in days){
        if(days[day].unavailable)
            workingHours+=   day+" : Unavailable;";
        else
            workingHours+=   day+" : \n"+days[day].time.toString()+";";
    
       // workingHours+="\n\n\n";
      }
      return workingHours;
    
  }

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              {/* <a href="javascript:;">User List</a> */}
              <NavLink to={"/guide-list"}>Guide List</NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">
              Edit Guide Details
            </li>
          </ol>
          <h1 className="page-header">
            <i
              className="fa fa-arrow-left edit"
              onClick={() => navigate("/guide-list")}
              style={{
                  cursor: "pointer",
                  fontSize: "20px",
                  marginTop: "7px",
                  marginRight: "10px",
              }}
            ></i>
            Guide Details
          </h1>

                  {/* <div className="btn btn-success mt-2 px-3">Verify</div> */}
          <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "20px", marginTop: "10px", }} >
            <div className="row RestName p-5" style={{ borderRadius: "20px" }}>
              <div className="mx-auto ">
                <span style={{ fontSize: "18px", fontWeight: "700" }}>
                  Edit Details of {data?.name}
                </span>
                
              </div>
            </div>
            <br />
            
            <form onSubmit={{}} className="changePassForm">
                <div className="row">
                  <div className="mb-3 col-sm-6">
                    <label for="exampleInputOldPass">Name:</label>
                    <input
                      type="password" className="form-control ml-0" placeholder="Enter Old Password"
                      id="exampleInputOldPass" name="old_password"
                    //   value={password.old_password}
                    //   onChange={changePassword}
                    />
                    <div className="text-danger "> {/* {passErrors.old_password} */} </div>
                  </div>

                  <div className="mb-3 col-sm-6">
                    <label for="exampleInputNewPass">Phone No:</label>
                    <input type="password" className="form-control ml-0" placeholder="Enter New Password"
                        id="exampleInputNewPass"  name="new_password"
                    //   value={password.new_password}
                    //   onChange={changePassword}
                    />
                    <div className="text-danger ">
                      {/* {passErrors.new_password} */}
                    </div>
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label for="exampleInputConfirmPass">Date of birth:</label>
                    <input type="date" className="form-control ml-0" placeholder="Enter Confirm Password"
                      id="exampleInputConfirmPass" name="confirm_password"
                      value={{}} onChange={{}}
                    />
                    <div className="text-danger "> {/* {passErrors.confirm_password} */} </div>
                    <div className="text-danger "> {/* {passErrors.password_error} */} </div>
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label for="exampleInputConfirmPass">&nbsp;</label><br/>
                    <button type="submit" className="btn m-r-5 btn-success form-control ml-0"
                        disabled={disable} style={{width:"fit-content"}}
                    >
                        {disable ? "Processing..." : "Submit"}
                    </button>
                  
                  </div>
                </div>
            </form>

          </div>
        </div>
      </div>
    </>
  );
}
