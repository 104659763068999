import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loader from './include/Loader'
import Menu from './include/Menu'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Spinner from './include/Spinner';
import axios from 'axios';
import { D_URL } from "./baseurl";
import { toast } from "react-toastify";


const Cms = () => {

  const [disable, setDisable] = useState(true);
  const navigate = useNavigate();
  const [aboutUs, setAboutUs] = useState('')
  const [terms, setTerms] = useState('')
  const [policy, setPolicy] = useState('')
  const [entity, setEntity] = useState([]);


  const [aboutUsGuru, setAboutUsGuru] = useState('')
  const [termsGuru, setTermsGuru] = useState('')
  const [policyGuru, setPolicyGuru] = useState('')

  useEffect(() => {
    if (localStorage.getItem("OM_Admin_ID") == null) {
      navigate("/om-outline-admin");
      return;
    }
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    if (disable == true) {
      setEntity([]);
      getCmsList();
    }
  }, [disable]);

  const getCmsList = () => {
    const myurl = `${D_URL}/get-cms`;

    axios({
      method: "post",
      url: myurl,
      // data: fd,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('OM_Admin_ID') },
    })
      .then(async (response) => {
        console?.log("@@", response?.data);
        if (response?.data?.status) {

          setAboutUs(response?.data?.data?.user_cms?.about_us)
          setPolicy(response?.data?.data?.user_cms?.privacy_policy)
          setTerms(response?.data?.data?.user_cms?.terms_condition)

          setAboutUsGuru(response?.data?.data?.guide_cms?.about_us)
          setPolicyGuru(response?.data?.data?.guide_cms?.privacy_policy)
          setTermsGuru(response?.data?.data?.guide_cms?.terms_condition)
          setDisable(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        console?.log("Errors", error);
      });
  };

  const UpdateCms = () => {

    const updatedData = {
      user_cms: {
        about_us: aboutUs,
        terms_condition: terms,
        privacy_policy: policy
      },
      guide_cms: {
        about_us: aboutUsGuru,
        terms_condition: termsGuru,
        privacy_policy: policyGuru
      }
    };

    const myurl = `${D_URL}/update-cms`;

    axios({
      method: "post",
      url: myurl,
      data: updatedData,
      headers: { "Content-Type": "application/json", Authorization: "Barrer " + localStorage.getItem('OM_Admin_ID') },
    })
      .then(async (response) => {
        console?.log("@@", response?.data);
        if (response?.data?.status) {
          getCmsList();
          setDisable(false);
          toast.success(response?.data?.message)
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        console?.log("Errors", error);
      });
  };


  return (
    <>
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              <a href="javascript:;">Home</a>
            </li>
            <li className="breadcrumb-item active currentPath">
              CMS Management
            </li>
          </ol>
          <h1 className="page-header">CMS Management</h1>
          {disable === false ? (
            <div className='mt-5'>
              <h3>Customer CMS management</h3>
              <div className='card mt-4'>
                <div className='card-body'>
                  <h4>About Us</h4>
                  <CKEditor id="editorAbout" name="aboutUs" editor={ClassicEditor} data={aboutUs} style={{ borderRadius: '15px' }}
                    onChange={(event, editor) => {
                      const newData = editor.getData();
                      setAboutUs(newData);
                    }} />
                  <button type='button' className='btn btn-success' style={{ width: "90px", marginTop: "20px" }} onClick={UpdateCms}>Save</button>
                </div>
              </div>
              <div className='card mt-5'>
                <div className='card-body'>
                  <h4>Privacy Policy</h4>
                  <CKEditor id="editorPolicy" name="policy" editor={ClassicEditor} data={policy} style={{ borderRadius: '15px' }}
                    onChange={(event, editor) => {
                      const newData = editor.getData();
                      setPolicy(newData);
                    }}
                  />
                  <button type='button' className='btn btn-success' style={{ width: "90px", marginTop: "20px" }} onClick={UpdateCms}>Save</button>
                </div>
              </div>
              <div className='card mt-5'>
                <div className='card-body'>
                  <h4>Terms and Condition</h4>
                  <CKEditor id="editor" name="terms" editor={ClassicEditor} data={terms} style={{ borderRadius: '15px' }}
                    onChange={(event, editor) => {
                      const newData = editor.getData();
                      setTerms(newData);
                    }}
                  />
                  <button type='button' className='btn btn-success' style={{ width: "90px", marginTop: "20px" }} onClick={UpdateCms}>Save</button>
                </div>
              </div>

              <h3 className='mt-5'>Guru CMS Management</h3>
              <div className='card mt-4'>
                <div className='card-body'>
                  <h4>About Us</h4>
                  <CKEditor id="editor" name="aboutUsGuru" editor={ClassicEditor} data={aboutUsGuru} style={{ borderRadius: '15px' }}
                    onChange={(event, editor) => {
                      const newData = editor.getData();
                      setAboutUsGuru(newData);
                    }}
                  />
                  <button type='button' className='btn btn-success' style={{ width: "90px", marginTop: "20px" }} onClick={UpdateCms}>Save</button>
                </div>
              </div>
              <div className='card mt-5'>
                <div className='card-body'>
                  <h4>Privacy Policy</h4>
                  <CKEditor id="editor" name="policyGuru" editor={ClassicEditor} data={policyGuru} style={{ borderRadius: '15px' }}
                    onChange={(event, editor) => {
                      const newData = editor.getData();
                      setPolicyGuru(newData);
                    }}
                  />
                  <button type='button' className='btn btn-success' style={{ width: "90px", marginTop: "20px" }} onClick={UpdateCms}>Save</button>
                </div>
              </div>
              <div className='card mt-5'>
                <div className='card-body'>
                  <h4>Terms and Condition</h4>
                  <CKEditor id="editor" name="termsGuru" editor={ClassicEditor} data={termsGuru} style={{ borderRadius: '15px' }}
                    onChange={(event, editor) => {
                      const newData = editor.getData();
                      setTermsGuru(newData);
                    }}
                  />
                  <button type='button' className='btn btn-success' style={{ width: "90px", marginTop: "20px" }} onClick={UpdateCms}>Save</button>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </>
  )
}

export default Cms