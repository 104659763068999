import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Menu() {
  const navigate = useNavigate();


  useEffect(() => {
    console.log(localStorage.getItem("OM_Admin_ID"))
    if (localStorage.getItem("OM_Admin_ID") == null) {
      toast.error("!..Please login first");
      navigate("/om-outline-admin");
    }
  }, []);
  const Logout = () => {
    localStorage.removeItem("OM_Admin_ID");
    localStorage.removeItem("OM_Admin_EMAIL");
    localStorage.removeItem("OM_Admin_NAME");
    localStorage.removeItem("OM_Admin_IMAGE");
    navigate("/om-outline-admin");
    toast.success("!Logout Successfully");
  };

  var dashboardClass = window.location.pathname.match(/^\/dashboard/)
    ? "active"
    : "";

  var userListClass =
    window.location.pathname.match(/^\/users-list/) ||
      window.location.pathname.match(/^\/users-details/)
      ? "active"
      : "";
  var schoolCompanyClass =
    window.location.pathname.match(/^\/group-list/)
      ? "active"
      : "";

  var driversListClass =
    window.location.pathname.match(/^\/drivers-list/) ||
      window.location.pathname.match(/^\/drivers-details/)
      ? "active"
      : "";
  var eventClass =
    window.location.pathname.match(/^\/classes-list/) ||
      window.location.pathname.match(/^\/add-classes/) ||
      window.location.pathname.match(/^\/edit-classes/)
      ? "active"
      : "";
  var donationClass =
    window.location.pathname.match(/^\/donation-list/)
      ? "active"
      : "";
  var subscriptionClass =
    window.location.pathname.match(/^\/subscription-list/)
      ? "active"
      : "";

  var subscriptionPlanClass =
    window.location.pathname.match(/^\/subscription-plan-list/)
      ? "active"
      : "";

  var contactusClass =
    window.location.pathname.match(/^\/contact-us-list/)
      ? "active"
      : "";
  var liveStreamDemoClass =
    window.location.pathname.match(/^\/live-stream-demo/)
      ? "active"
      : "";
  var coursesClass =
    window.location.pathname.match(/^\/courses-list/)
      ? "active"
      : "";

  var aboutListClass =
    window.location.pathname.match(/^\/about-us/)
      // window.location.pathname.match(/^\/users-details/)
      ? "active"
      : "";

  var tandcListClass =
    window.location.pathname.match(/^\/terms-and-condition/)
      // window.location.pathname.match(/^\/users-details/)
      ? "active"
      : "";

  var ppListClass =
    window.location.pathname.match(/^\/privacy-policy/)
      // window.location.pathname.match(/^\/users-details/)
      ? "active"
      : "";

  var cmsListClass =
    window.location.pathname.match(/^\/cms-list/)
      // window.location.pathname.match(/^\/users-details/)
      ? "active"
      : "";

  var blogClass =
    window.location.pathname.match(/^\/blogs-list/)
      ? "active"
      : "";

  var classesDescriptionClass = window.location.pathname.match(/^\/classes-description-list/)
    ? "active" : "";

    var homeDescriptionClass = window.location.pathname.match(/^\/manage-home-description/)
    ? "active" : "";

  // useEffect(() => {
  //   localStorage.getItem("visible")
  //     ? localStorage.setItem("visible", visible)
  //     : localStorage.setItem("visible", false);
  //   console.log(localStorage.getItem("visible"));
  // }, [visible]);

  return (
    <>
      <div id="header" className="header navbar-default">
        <div className="navbar-header">
          <NavLink to="/dashboard" className="navbar-brand ">
            {/* <img
              src="/assets/img/logo/Vector.png"
              alt="Chef-event-logo"
              className="d-inline-block align-text-top mr-2"
            /> */}
            <span className="logo"></span>
            <span className="BrudAdmin">
              <b>OM Outline</b>
            </span>
          </NavLink>

          <button
            type="button"
            className="navbar-toggle"
            data-click="sidebar-toggled"
          >
            <span className="icon-bar" style={{ color: "#fff" }}></span>
            <span className="icon-bar" style={{ color: "#fff" }}></span>
            <span className="icon-bar" style={{ color: "#fff" }}></span>
          </button>
        </div>

        <ul className="navbar-nav navbar-right admin">
          <li className="dropdown navbar-user">
            <span className="dropdown-toggle" data-toggle="dropdown">
              <img src={localStorage.getItem("OM_Admin_IMAGE")} alt="" />
              <span className="d-none d-md-inline userName">
                {localStorage.getItem("OM_Admin_NAME")}
              </span>{" "}
              <i class="fa fa-caret-down "></i>
            </span>
            <div
              className="dropdown-menu "
              style={{ borderRadius: "00px", padding: "5px ", width: '-webkit-fill-available' }}
            >
              <NavLink
                to="/admin-profile"
                style={{ textDecoration: "none", color: "#404252" }}
              >
                <span className="dropdown-item">
                  <span className="DropdownItem">Edit Profile</span>
                </span>
              </NavLink>
              <span className="dropdown-item" onClick={Logout}>
                <span className="DropdownItem">
                  <span className="DropdownItem ml-0">Log Out</span>
                </span>
              </span>
            </div>
          </li>
        </ul>
      </div>

      <div id="sidebar" className="sidebar" style={{ overflow: "auto" }}>
        <div data-scrollbar="true" data-height="100%">
          <div className="nav-header"></div>
          <ul className="nav">
            <li className="sidebarList">
              <NavLink
                to="/dashboard"
                className={dashboardClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="fa fa-th-large menuIcon"></i>
                  <span className="menuItem">Dashboard</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/user-list"
                className={userListClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="fa fa-users menuIcon"></i>
                  <span className="menuItem">Users</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/group-list"
                className={schoolCompanyClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="fa fa-building menuIcon"></i>
                  <span className="menuItem">Group</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/guide-list"
                className={driversListClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  {/* <i class="fa fa-hat-chef menuIcon"></i> */}
                  <i class="fa fa-user-tie menuIcon"></i>
                  <span className="menuItem">Guide</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/classes-list"
                className={eventClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  {/* <i class="fa fa-hat-chef menuIcon"></i> */}
                  <i class="fa fa-tasks menuIcon"></i>
                  <span className="menuItem">Classes</span>
                </div>
              </NavLink>
            </li>

            {/* <li className="sidebarList">
              <NavLink
                to="/donation-list"
                className={donationClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fa fa-dollar-sign menuIcon"></i>
                  <span className="menuItem">Donation</span>
                </div>
              </NavLink>
            </li> */}

            <li className="sidebarList">
              <NavLink
                to="/subscription-list"
                className={subscriptionClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  {/* <i class="fa fa-hat-chef menuIcon"></i> */}
                  <i class="fas fa-donate menuIcon"></i>
                  <span className="menuItem">Subscription</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/subscription-plan-list"
                className={subscriptionPlanClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fas fa-clipboard-list menuIcon"></i>
                  <span className="menuItem">Subscription Plan</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/courses-list"
                className={coursesClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fas fa-graduation-cap menuIcon"></i>
                  <span className="menuItem">Courses</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/blogs-list"
                className={blogClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fas fa-blog menuIcon"></i>
                  <span className="menuItem">Blogs</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/classes-description-list"
                className={classesDescriptionClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fas fa-list menuIcon"></i>
                  <span className="menuItem">Classes Description</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/manage-home-description"
                className={homeDescriptionClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fas fa-home menuIcon"></i>
                  <span className="menuItem">Home Description</span>
                </div>
              </NavLink>
            </li>

            <li className="sidebarList">
              <NavLink
                to="/contact-us-list"
                className={contactusClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fas fa-clipboard-list menuIcon"></i>
                  <span className="menuItem">Contact Us</span>
                </div>
              </NavLink>
            </li>

            {/* <li className="sidebarList">
              <NavLink
                to="/live-stream-demo"
                className={liveStreamDemoClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fas fa-video menuIcon"></i>
                  <span className="menuItem">Live Stream Demo</span>
                </div>
              </NavLink>
            </li> */}

            <li className="sidebarList">
              <NavLink
                to="/cms-list"
                className={cmsListClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fas fa-file menuIcon"></i>
                  <span className="menuItem">CMS management</span>
                </div>
              </NavLink>
            </li>
            {/* <li className="sidebarList">
              <NavLink
                to="/events"
                className={eventListClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <EventIcon
                    className="menuIcon"
                    style={{ fontSize: '20px', marginTop: '-4px' }}
                  />
                  <span className="menuItem">Event</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/billing"
                className={billingClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i class="fa fa-money-bill menuIcon"></i>
                  <span className="menuItem">Billing</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/booking"
                className={bookingClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <CollectionsBookmarkIcon
                    className="menuIcon"
                    style={{ fontSize: '20px', marginTop: '-4px' }}
                  />
                  <span className="menuItem">Booking</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/feedback"
                className={feedbackClass}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <FeedbackIcon
                    className="menuIcon"
                    style={{ fontSize: '20px', marginTop: '-4px' }}
                  />
                  <span className="menuItem">Feedback</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/terms-and-conditions"
                className={TandC}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="menuIcon">
                    <GavelIcon
                      style={{ fontSize: '20px', marginTop: '-4px' }}
                    />
                  </i>
                  <span className="menuItem">T & C</span>
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/privacy-policy"
                className={pp}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="menuIcon">
                    <PolicyIcon
                      style={{ fontSize: '20px', marginTop: '-4px' }}
                    />
                  </i>
                  <span className="menuItem">Policy</span>{' '}
                </div>
              </NavLink>
            </li>
            <li className="sidebarList">
              <NavLink
                to="/about-us"
                className={aboutus}
                activeClassName="active"
              >
                <div className="menuItemDiv">
                  <i className="menuIcon">
                    <InfoIcon style={{ fontSize: '20px', marginTop: '-4px' }} />
                  </i>
                  <span className="menuItem">AboutUs</span>
                </div>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Menu;
