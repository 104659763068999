import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { D_URL } from "../baseurl";
import { toast } from 'react-toastify';

import Loader from '../include/Loader';
import Menu from '../include/Menu';
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";

const ManageHome = () => {

  const [change, setChange] = useState(true);
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [classData, setClassData] = useState({ title: "", sub_title: "", image: "" })

  const [img, setImg] = useState({
    src: "",
    alt: "",
  });

  const nav = useNavigate();

  const [isPicUpload, setIsPicUpload] = useState(false);
  const [pictureName, setPictureName] = useState("")
  const location = useLocation();

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, [change]);

  useEffect(() => {
    getHome();
  }, [])

  const InputEvent = (e) => {
    setClassData({ ...classData, [e.target.name]: e.target.value })
  }

  const getHome = () => {
    const myurl = `${D_URL}/get-homeImage`;
    // const fd = new FormData();

    // fd.append("page", currentPage);
    axios({
      method: "get",
      url: myurl,
      // data: fd,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('OM_Admin_ID') },
    }).then(async (response) => {
      console?.log("@@", response?.data);
      if (response?.data?.status) {
        let data = await response?.data?.data;
        // classData(data);
        setClassData({
          title: data.title,
          sub_title: data.sub_title,
          image: data.image,
        });
        setDisable(false);
      } else {
        toast.error("Something went wrong");
      }
    }).catch((error) => {
      toast.error("Something went wrong");
      console?.log("Errors", error);
    });
  }

  const handleSubmit = () => {
    if (validate()) {
      setDisable(true);

      const myurl = `${D_URL}/manage-homeImage`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("title", classData.title);
      bodyFormData.append("sub_title", classData.sub_title);
      bodyFormData.append("image", classData.image);

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: 'Bearer ' + localStorage.getItem("OM_Admin_ID") },
      }).then((response) => {
        console.log("@@", response?.data)
        if (response.data.status) {
          toast.success(response?.data?.message);
          setDisable(false);
        }
      }).catch((error) => {
        console.log("Error", error)
        setDisable(false);
      })

    }
  }

  const handleImg = (e) => {
    if (e?.target?.files[0]) {
      var image = new Image();
      image.src = URL.createObjectURL(e?.target?.files[0]);
      image.onload = function () {

        setImg({
          src: URL.createObjectURL(e?.target?.files[0]),
          alt: e?.target?.files[0].name,
        });
        setDisable(true);
        setIsPicUpload(true);
        const myurl = `${process.env.REACT_APP_BASE_URL}api/v1/upload-image`;
        var bodyFormData = new FormData();
        bodyFormData.append("image", e?.target?.files[0]);

        axios({
          method: "post",
          url: myurl,
          data: bodyFormData,
        })

          .then((result) => {
            console.log("=====", result);

            if (result?.data?.status == true) {
              //console.log('Success:=====',result?.data?.data?.filepath_url);
              setClassData({ ...classData, image: result?.data?.data?.url });
              setDisable(false);
            } else {
              console.log("Error:=====");
              // console.log('Error:=====', result?.data);
              setIsPicUpload(false);
              setPictureName("");
              setDisable(false);
            }
          })
          .catch((error) => {
            console.log(error);
            //console.log('Errors', error);
            setIsPicUpload(false);
            setPictureName("");
            setDisable(false);
          });
      }
    }
  }


  const validate = () => {
    let input = classData;

    let errors = {};
    let isValid = true;

    if (!input["title"]) {
      isValid = false;
      errors["title_err"] = "Please enter Home title";
    }
    if (!input["sub_title"]) {
      isValid = false;
      errors["sub_title_err"] = "Please enter Home sub-title";
    }
    if (!input["image"]) {
      isValid = false;
      errors["img_err"] = "Please select Home image";
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <div>
      <>
        <Loader />
        <div
          id="page-container"
          className="fade page-sidebar-fixed page-header-fixed"
        >
          <Menu />
          <div id="content" className="content">
            <ol className="breadcrumb float-xl-right">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard">
                  <span className="basePath">Home</span>
                </NavLink>
              </li>
              <li className="breadcrumb-item active currentPath">Manage Home Description</li>
            </ol>
            <h1 className="page-header">Manage Home Description </h1>

            <div className="row">
              <div className="col-xl-12 p-5">
                <div
                  className="card "
                  style={{
                    height: "auto",
                    padding: "20px",
                    borderRadius: "20px",
                  }}
                >

                  <form className="profileForm row" >
                    <div className="col-sm-6 mb-3">
                      <label for="exampleInputEmail1" className="form-label">Home title : </label>
                      <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter Blog title here.." name="title"
                        value={classData.title} onChange={InputEvent} />
                      <div className="text-danger">{errors.title_err}</div>
                    </div>


                    <div className="col-sm-6 mb-3">
                      <label for="exampleInputEmail1" className="form-label">Home sub-title : </label>
                      <textarea className="form-control ml-0" id="exampleInputName" placeholder="Enter description here.." name="sub_title"
                        onChange={InputEvent} value={classData.sub_title} rows="3"></textarea>

                      <div className="text-danger">{errors.sub_title_err}</div>
                    </div>

                    <div className="col-sm-6 mb-3">
                      <label for="exampleInputEmail1" className="form-label">Home Images</label>
                      <input
                        type="file"
                        className="form-control imgInput ml-0 mt-1"
                        id="exampleInputImage"
                        onChange={handleImg}
                        accept="image/png, image/gif, image/jpeg"
                      />
                      <div>
                        {classData.image !== "" ? ( // Check if image URL is not empty
                          <img
                            src={classData.image} // Set image src from classData state
                            className="form-img__img-preview mt-1"
                            style={{ width: "40%", height: "auto", borderRadius: "9px" }}
                            alt="imgs"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="text-danger">{errors.img_err}</div>
                    </div>


                    <div className="col-sm-6 mb-3">
                      <label for="exampleInputImage" className="form-label">&nbsp;</label>
                      <div className="ml-0 mt-1">
                        <button type="button" className="btn  btn-success mr-2" onClick={handleSubmit} disabled={disable}>
                          {disable ? "Processing..." : "Submit"}</button>
                        <button type="reset" className="btn btn-dark" value="Reset"
                          onClick={(e) => {
                            setChange(!change);
                            setIsPicUpload(false);
                            setImg({ src: "", alt: "" })
                            setClassData({ image: "", title: "", describe: "" })
                          }}
                        >Reset</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default ManageHome