import React, { useState, useEffect } from 'react'
import Loader from '../include/Loader';
import Menu from '../include/Menu';
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { D_URL } from "../baseurl";
import { toast } from 'react-toastify';

import CropEasy from '../CropImage/CropEasy';
import Footer from '../include/Footer';



const AddBlog = () => {

  const [change, setChange] = useState(true);
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [ blog, setBlog] = useState({})

  const [img, setImg] = useState({
    src: "",
    alt: "",
  });

  const [isPicUpload, setIsPicUpload] = useState(false);
  const [pictureName, setPictureName] = useState("")
  const location = useLocation();

  const nav = useNavigate()

  useEffect(() => {
    if (location.state) {
      setBlog({ title: location.state.title, description: location.state.description, image: location.state.image });
      setImg({ src: location.state.image });
    }
  }, [location.state])

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, [change]);

  const InputEvent = (e) => {
    setBlog({ ...blog, [e.target.name]: e.target.value })
  }

  const data = location.state

  console.log(data, "data")


  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      setDisable(true);

      const myurl = `${D_URL}/manage-blog`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("title", blog.title);
      bodyFormData.append("description", blog.description);
      bodyFormData.append("image", blog.image);
      bodyFormData.append('id', location?.state?._id ?? '');

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: 'Bearer ' + localStorage.getItem("OM_Admin_ID") },
      }).then((response) => {
        console.log("@@", response?.data)
        if (response.data.status) {
          toast.success(response?.data?.message)
          nav('/blogs-list')
        }
      }).catch((error) => {
        console.log("Error", error)
      })

    }
  }

  // const handleImg = (e) => {
  //   if (e?.target?.files[0]) {
  //     var image = new Image();
  //     image.src = URL.createObjectURL(e?.target?.files[0]);
  //     image.onload = function () {

  //       setImg({
  //         src: URL.createObjectURL(e?.target?.files[0]),
  //         alt: e?.target?.files[0].name,
  //       });
  //       setDisable(true);
  //       setIsPicUpload(true);
  //       const myurl = `${process.env.REACT_APP_BASE_URL}api/v1/upload-image`;
  //       var bodyFormData = new FormData();
  //       bodyFormData.append("image", e?.target?.files[0]);

  //       axios({
  //         method: "post",
  //         url: myurl,
  //         data: bodyFormData,
  //       })

  //         .then((result) => {
  //           console.log("=====", result);

  //           if (result?.data?.status == true) {
  //             //console.log('Success:=====',result?.data?.data?.filepath_url);
  //             setBlog({ ...blog, image: result?.data?.data?.url });
  //             setDisable(false);
  //           } else {
  //             console.log("Error:=====");
  //             // console.log('Error:=====', result?.data);
  //             setIsPicUpload(false);
  //             setPictureName("");
  //             setDisable(false);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           //console.log('Errors', error);
  //           setIsPicUpload(false);
  //           setPictureName("");
  //           setDisable(false);
  //         });
  //     }
  //   }
  // }

  function blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
  }



  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const croppedImage = (file, url) => {
    var myFile = blobToFile(file, file.name);
    console.log(myFile, "files")
    // setImage(myFile);
    setShowImage();
    setOpenCrop(false);

    setDisable(true);

    const myurl = `${process.env.REACT_APP_BASE_URL}api/v1/upload-image`;
    const bodyFormData = new FormData();
    bodyFormData.append('image', myFile);

    axios({
      method: 'post',
      url: myurl,
      data: bodyFormData,
    })
      .then((result) => {
        console.log(result);

        if (result?.data?.status == true) {
          console.log('Success:=====', result?.data?.data?.filepath_url);
          setBlog({ ...blog, image: result?.data?.data?.url });
          setDisable(false);
        } else {
          console.log("Error:=====");
          console.log('Error:=====', result?.data);
          setIsPicUpload(false);
          setPictureName("");
          setDisable(false);
        }

      })
      .catch((error) => {
        console.log(error, "$$-error");
        setIsPicUpload(false);
        setPictureName("");
        setDisable(false);
      })
  }

  const handleImg = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      setImage(image);
      setShowImage(URL.createObjectURL(image));
      setOpenCrop(true);
      console.log(showImage, "images");
    }
  }

  const validate = () => {
    let input = blog;

    let errors = {};
    let isValid = true;

    if (!input["title"]) {
      isValid = false;
      errors["title_err"] = "Please enter Blog title";
    }
    if (!input["description"]) {
      isValid = false;
      errors["description_err"] = "Please enter Blog description";
    }
    if (!input["image"]) {
      isValid = false;
      errors["img_err"] = "Please select image";
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <div>
      <>
        <Loader />
        <div
          id="page-container"
          className="fade page-sidebar-fixed page-header-fixed"
        >
          <Menu />
            <div id="content" className="content">
              <ol className="breadcrumb float-xl-right">
                <li className="breadcrumb-item">
                  <NavLink to="/dashboard">
                    <span className="basePath">Home</span>
                  </NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to="/blogs-list">
                    <span className="basePath">Blogs</span>
                  </NavLink>
                </li>
                <li className="breadcrumb-item active currentPath">Manage Blog</li>
              </ol>
              <h1 className="page-header">Add Blog</h1>

              <div className="row">
                <div className="col-xl-12 p-5">
                  <div
                    className="card "
                    style={{
                      height: "auto",
                      padding: "20px",
                      borderRadius: "20px",
                    }}
                  >

                    <form onSubmit={(e) => handleSubmit(e)} className="profileForm row" >
                      <div className="col-sm-6 mb-3">
                        <label for="exampleInputEmail1" className="form-label">Blog title : </label>
                        <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter Blog title here.." name="title"
                          value={blog.title} onChange={InputEvent} />
                        <div className="text-danger">{errors.title_err}</div>
                      </div>


                      <div className="col-sm-6 mb-3">
                        <label for="exampleInputEmail1" className="form-label">Blog Description : </label>
                        <textarea className="form-control ml-0" id="exampleInputName" placeholder="Enter description here.." name="description"
                          onChange={InputEvent} value={blog.description} rows="3"></textarea>

                        <div className="text-danger">{errors.description_err}</div>
                      </div>

                      <div className="col-sm-6 mb-3">
                        <label for="exampleInputEmail1" className="form-label">Blog Images</label>
                        <input
                          type="file"
                          className="form-control imgInput ml-0 mt-1"
                          id="exampleInputImage"
                          onChange={handleImg}
                          accept="image/png, image/gif, image/jpeg"
                        />
                        <div>
                          <CropEasy
                            photoURL={showImage}
                            file={image}
                            activeModal={openCrop}
                            // setActiveModal={()}
                            croppedImage={croppedImage}
                          />

                          {/* {img.src != "" ? (
                            <img
                              src={blog.image}
                              className="form-img__img-preview mt-1"
                              style={{ width: "40%", height: "auto", borderRadius: "9px" }}
                              alt="imgs"
                            />
                          ) : (
                            ""
                          )} */}
                          {blog.image ? <img src={blog.image} style={{ width: '300px', height: '130px', objectFit: 'cover', borderRadius: '10px', marginTop: "10px" }} /> : ''}
                        </div>
                        <div className="text-danger">{errors.img_err}</div>
                      </div>


                      <div className="col-sm-6 mb-3">
                        <label for="exampleInputImage" className="form-label">&nbsp;</label>
                        <div className="ml-0 mt-1">
                          <button type="submit" className="btn  btn-success mr-2" disabled={disable}>
                            {disable ? "Processing..." : "Submit"}</button>
                          <button type="reset" className="btn btn-dark" value="Reset"
                            onClick={(e) => {
                              setChange(!change);
                              setIsPicUpload(false);
                              setImg({ src: "", alt: "" })
                              setBlog({ image: "", title: "", description: "" })
                            }}
                          >Reset</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          <Footer />
        </div>
      </>
    </div>
  )
}

export default AddBlog