import React, { useState, useEffect, useRef } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { D_URL } from "../baseurl";
import Footer from "../include/Footer";
import Loader from "../include/Loader";
import Menu from "../include/Menu";

// import Cropper from 'react-easy-crop'

import CropEasy from '../CropImage/CropEasy';


// import Cropper from 'react-cropper';
// import 'cropperjs/dist/cropper.css'; 


const AddCourses = () => {

  const [change, setChange] = useState(true);
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, [change]);


  const [img, setImg] = useState({
    src: "",
    alt: "",
  });
  const [coursesData, setCoursesData] = useState({});
  const [isPicUpload, setIsPicUpload] = useState(false);
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [pictureName, setPictureName] = useState("");

  // const handleImg = (e) => {
  //   if (e?.target?.files[0]) {
  //     var image = new Image();
  //     image.src = URL.createObjectURL(e?.target?.files[0]);
  //     image.onload = function () {
  //       if (this.width > 1000 || this.height > 1000) {
  //         toast.error('Image should be under 1000 * 1000 resolution')
  //       } else {
  //         setImg({
  //           src: URL.createObjectURL(e?.target?.files[0]),
  //           alt: e?.target?.files[0].name,
  //         });
  //         setDisable(true);
  //         setIsPicUpload(true);
  //         const myurl = `${process.env.REACT_APP_BASE_URL}api/v1/upload-image`;
  //         var bodyFormData = new FormData();
  //         bodyFormData.append("image", e?.target?.files[0]);

  //         axios({
  //           method: "post",
  //           url: myurl,
  //           data: bodyFormData,
  //         })

  //           .then((result) => {
  //             console.log("=====", result);

  //             if (result?.data?.status == true) {
  //               //console.log('Success:=====',result?.data?.data?.filepath_url);
  //               setPictureName(result?.data?.data?.url);
  //               setDisable(false);
  //             } else {
  //               console.log("Error:=====");
  //               // console.log('Error:=====', result?.data);
  //               setIsPicUpload(false);
  //               setPictureName("");
  //               setDisable(false);
  //             }
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //             //console.log('Errors', error);
  //             setIsPicUpload(false);
  //             setPictureName("");
  //             setDisable(false);
  //           });
  //       }
  //     }
  //   }
  // };


  // const cropperRef = useRef(null);


  // const handleImg = (e) => {
  //   if (e?.target?.files[0]) {
  //     const file = e.target.files[0];
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       setImg({
  //         src: reader.result,
  //         alt: file.name,
  //       });
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  // const handleCrop = () => {
  //   if (cropperRef.current) {

  //     const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
  //     const croppedImage = croppedCanvas.toDataURL();


  //     console.log(croppedImage, "image url")


  //     setDisable(true);

  //     const myurl = `${process.env.REACT_APP_BASE_URL}api/v1/upload-image`;
  //     const bodyFormData = new FormData();
  //     bodyFormData.append('image', dataURLtoFile(croppedImage, 'cropped.jpg'));

  //     axios({
  //       method: 'post',
  //       url: myurl,
  //       data: bodyFormData,
  //     })
  //       .then((result) => {
  //         console.log(result);

  //         if (result?.data?.status == true) {
  //           console.log('Success:=====',result?.data?.data?.filepath_url);
  //           setPictureName(result?.data?.data?.url);
  //           setDisable(false);
  //         } else {
  //           console.log("Error:=====");
  //           console.log('Error:=====', result?.data);
  //           setIsPicUpload(false);
  //           setPictureName("");
  //           setDisable(false);
  //         }

  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setIsPicUpload(false);
  //         setPictureName("");
  //         setDisable(false);
  //       })
  //   }
  // };


  // function dataURLtoFile(dataURL, filename) {
  //   const arr = dataURL.split(',');
  //   const mime = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // }


  const InputEvent = (e) => {
    const newCousesData = { ...coursesData };
    newCousesData[e.target.name] = e.target.value;
    setCoursesData(newCousesData);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log('oooooo');
    if (validate()) {
      setDisable(true);

      const { name } = coursesData;
      const myurl = `${D_URL}/add-course`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("name", coursesData.name);
      bodyFormData.append("description", coursesData.description);
      bodyFormData.append("image", coursesData.image);

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: 'Barrer ' + localStorage.getItem("OM_Admin_ID") },
      })
        .then((response) => {
          console.log(response.data)
          if (response?.data?.status === true) {

            setDisable(false);
            console.log("===SUCCESSFUL===", response?.data);
            toast.success("Courses added successfully");
            navigate('/add-video', { state: response.data.data._id });

          } else {
            console.log("===Error===");
            // toast.error(response?.data?.message)
            //console.log('===Error===', response?.data);
            setDisable(false);
            setPictureName({});
            setIsPicUpload(false);
          }
        })
        .catch((error) => {
          //console.log('Errors', error);
          console.log("Errors");
          toast.error("Something went wrong");
          setPictureName({});
          setDisable(false);
          setIsPicUpload(false);
        });
    }
    setChange(!change);
  };

  // crop images
  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  function blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
  }

  const croppedImage = (file, url) => {
    var myFile = blobToFile(file, file.name);
    console.log(myFile, "files")
    // setImage(myFile);
    setShowImage();
    setOpenCrop(false);

    setDisable(true);

    const myurl = `${process.env.REACT_APP_BASE_URL}api/v1/upload-image`;
    const bodyFormData = new FormData();
    bodyFormData.append('image', myFile);

    axios({
      method: 'post',
      url: myurl,
      data: bodyFormData,
    })
      .then((result) => {
        console.log(result);

        if (result?.data?.status == true) {
          console.log('Success:=====', result?.data?.data?.filepath_url);
          setCoursesData({ ...coursesData, image: result?.data?.data?.url });
          setDisable(false);
        } else {
          console.log("Error:=====");
          console.log('Error:=====', result?.data);
          setIsPicUpload(false);
          setPictureName("");
          setDisable(false);
        }

      })
      .catch((error) => {
        console.log(error, "$$-error");
        setIsPicUpload(false);
        setPictureName("");
        setDisable(false);
      })
  }

  const handleImage = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      setImage(image);
      setShowImage(URL.createObjectURL(image));
      setOpenCrop(true);
      console.log(showImage, "images");
    }
  }

  const validate = () => {
    let input = coursesData;

    let errors = {};
    let isValid = true;


    if (!input["name"]) {
      isValid = false;
      errors["name_err"] = "Please enter title";
    }
    if (!input["description"]) {
      isValid = false;
      errors["description_err"] = "Please enter description";
    } else if (input["description"].length < 25) {
      isValid = false;
      errors["description_err"] = "Description must be at least 25 characters long";
    }
    if (!input["image"]) {
      isValid = false;
      errors["img_err"] = "Please select image";
    }

    setErrors(errors);
    return isValid;
  }

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/courses-list">
                <span className="basePath">Courses</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Add</li>
          </ol>
          <h1 className="page-header">Add Courses</h1>

          <div className="row">
            <div className="col-xl-12 p-5">
              <div
                className="card "
                style={{
                  height: "auto",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >

                <form onSubmit={(e) => submitHandler(e)} className="profileForm row" >
                  <div className="col-sm-6 mb-3">
                    <label for="exampleInputEmail1" className="form-label"> Course tittle : </label>
                    <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter title here.." name="name"
                      value={coursesData.name} onChange={InputEvent} />
                    <div className="text-danger">{errors.name_err}</div>
                  </div>

                  <div className="col-sm-6 mb-3">
                    <label for="exampleInputEmail1" className="form-label"> Course Description : </label>
                    <textarea className="form-control ml-0" id="exampleInputName" placeholder="Enter description here.." name="description"
                      onChange={InputEvent} value={coursesData.description} rows="3"></textarea>
                    <div className="text-danger">{errors.description_err}</div>
                  </div>

                  {/* <div className="col-sm-6 mb-3">
                    <label for="exampleInputImage" className="form-label">Thumbnail image: </label>
                    <input
                      type="file"
                      className="form-control imgInput ml-0 mt-1"
                      id="exampleInputImage"
                      onChange={handleImg}
                      accept="image/png, image/gif, image/jpeg"
                    />
                    {img.src != "" ? (
                      <img
                        src={img.src}
                        className="form-img__img-preview mt-1"
                        style={{ width: "84px", height: "84px" }}
                        alt="imgs"
                      />
                    ) : (
                      ""
                    )}
                    <div className="text-danger">{errors.img_err}</div>
                  </div> */}
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="exampleInputImage" className="form-label">Thumbnail image:</label>
                    <input
                      type="file"
                      className="form-control imgInput ml-0 mt-1"
                      id="exampleInputImage"
                      onChange={handleImage}
                      accept="image/png, image/gif, image/jpeg"
                    />
                    <CropEasy
                      photoURL={showImage}
                      file={image}
                      activeModal={openCrop}
                      // setActiveModal={()}
                      croppedImage={croppedImage}
                    />
                    {coursesData.image ? <img src={coursesData.image} style={{ width: '300px', height: '130px', objectFit: 'cover', borderRadius: '10px', marginTop: "10px" }} /> : ''}

                    <div className="text-danger">{errors.img_err}</div>
                  </div>

                  <div className="col-sm-6 mb-3">
                    <label for="exampleInputImage" className="form-label">&nbsp;</label>
                    <div className="ml-0 mt-1">
                      <button type="submit" className="btn btn-success mr-2" disabled={disable}>
                        {disable ? "Processing..." : "Submit"}</button>
                      <button type="reset" className="btn btn-dark" value="Reset"
                        onClick={(e) => {
                          setIsPicUpload(false);
                          setImg({ src: "", alt: "" });
                          setChange(!change);
                        }}
                      >Reset</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default AddCourses