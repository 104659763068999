import React, { useEffect } from "react";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ColDetail from "../general/ColDetail";
import ShowImage from "../general/ShowImage";
// import env from "react-dotenv";
import { D_URL } from "../baseurl";
import moment from "moment/moment";
import { Table } from "react-bootstrap";

export default function DriversDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  var data = location.state;
  console.log("~~~~~~~~~~", data);

  useEffect(() => {
    if (localStorage.getItem("OM_Admin_ID") == null) {
      navigate("/om-outline-admin");
      return;
    }
    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  const showWorkingHours = (days) => {
    console.log(days)
    let workingHours = '';
    for (var day in days) {
      console.log(days[day].length);
      if (days[day].length === 0) {
        workingHours += day + " : Unavailable;";
      }

      else
        // workingHours += day + " : \n" + days[day].time.toString() + ";";
        workingHours += `${day}: ${days[day] + ";"}`
      workingHours += "\n";
    }
    return workingHours;
  }

  // const showWorkingHours = (days) => {
  //   let workingHours = '';
  //   for (let day in days) {

  //     workingHours += `${day}: ${days[day]}; `;
  //   }
  //   return workingHours;

  // }


  const switchStatus = (status) => {
    let el;
    switch (status) {
      case 0:
        el = <span className="text-info">Unverified</span>;
        break;
      case 1:
        el = <span className="text-warning">Pending</span>;
        break;
      case 2:
        el = <span className="text-success">Approved</span>;
        break;
      case 3:
        el = <span className="text-danger">Blocked</span>;
        break;
      default:
        el = <span></span>;
        break;
    }
    return el;
  }

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              {/* <a href="javascript:;">User List</a> */}
              <NavLink to={"/guide-list"}>Guide List</NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">
              Guide Details
            </li>
          </ol>
          <h1 className="page-header">
            <i
              className="fa fa-arrow-left edit"
              onClick={() => navigate("/guide-list")}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                marginTop: "7px",
                marginRight: "10px",
              }}
            ></i>
            Guide Details
          </h1>

          {/* <div className="btn btn-success mt-2 px-3">Verify</div> */}
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "20px",
              marginTop: "10px",
            }}
          >
            <div className="row RestName p-5" style={{ borderRadius: "20px" }}>
              <div className="mx-auto ">
                <span style={{ fontSize: "18px", fontWeight: "700" }}>
                  Details of {data?.name}
                </span>
                {/* <i className="fa fa-edit ml-2" onClick={()=>{navigate("/edit-guru-details", {state: data,})}}></i> */}
              </div>
            </div>
            <br />



            <div className="row">
              <ShowImage
                title="Guide Image"
                data={data?.image}
                url={data?.image ? data?.image : './assets/img/icon/customer.png'}
                alt="DriverImage"
                dummyUrl={"/assets/img/icon/customer.png"}
              />
            </div>
            <br />
            <div className="row">

              <ColDetail title="Phone No" data={data?.mobileno} />
              <ColDetail title="Email" data={data?.email} />
              <ColDetail title="Bio" data={data?.bio} />
              <ColDetail title="Status" data={switchStatus(data?.status)} />

              <ColDetail title="Joining Date" data={moment(data?.createdAt).format("YYYY-MM-DD hh:mm:ss A")} />
              <ColDetail title="Date of birth" data={moment(data?.dob).format("YYYY-MM-DD")} />
              {/* <ColDetail title="Working Hours" data={showWorkingHours(data?.working_hours).replace(/;/g, '\n')} /> */}
            </div>
            <div>
              {/* <h5>Working Hours</h5> */}
              <div className="row">
                <ColDetail title="Working Hours" data={showWorkingHours(data?.working_hours).replace(/;/g, '\n')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
