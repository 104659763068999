import { TableHeader, Pagination, Search } from "../Table";
import React, { useState, useEffect, useMemo } from "react";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Footer from "../include/Footer";
import { Dropdown, Table } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import HistoryIcon from "@material-ui/icons/History";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Spinner from "../include/Spinner";
// import env from "react-dotenv";
import { D_URL } from "../baseurl";
import Excel from "exceljs";
// import moment from 'moment';
import { saveAs } from 'file-saver';
import { ZIM } from 'zego-zim-web';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';

function randomID(len) {
    let result = '';
    if (result) return result;
    var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
      maxPos = chars.length,
      i;
    len = len || 5;
    for (i = 0; i < len; i++) {
      result += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return result;
  }
  export function getUrlParams(
    url = window.location.href
  ) {
    let urlStr = url.split('?')[1];
    return new URLSearchParams(urlStr);
  }

export default function List() {
  const location = useLocation();
  const navigate = useNavigate();
  const [entity, setEntity] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [limit, setlimit] = useState(10);
  const [disable, setDisable] = useState(true);
  const [finalArray, setFinalArray] = useState([]);

  const roomID = getUrlParams().get('roomID') || randomID(5);
  let role_str = getUrlParams(window.location.href).get('role') || 'Host';
  const role =
    role_str === 'Host'
      ? ZegoUIKitPrebuilt.Host
      : role_str === 'Cohost'
      ? ZegoUIKitPrebuilt.Cohost
      : ZegoUIKitPrebuilt.Audience;

    let sharedLinks = [];
    if (role === ZegoUIKitPrebuilt.Host || role === ZegoUIKitPrebuilt.Cohost) {
    sharedLinks.push({
        name: 'Join as co-host',
        url:
        window.location.protocol + '//' + 
        window.location.host + window.location.pathname +
        '?roomID=' +
        roomID +
        '&role=Cohost',
    });
    }

    sharedLinks.push({
        name: 'Join as audience',
        url:
         window.location.protocol + '//' + 
         window.location.host + window.location.pathname +
          '?roomID=' +
          roomID +
          '&role=Audience',
    });


  const [toUserId, setToUserId] = useState('')
    const [token, setToken] = useState('04AAAAAGRN64wAEGR2bno0bzltZW81Nnl4dXkAoLVLIwFzRHpp+LQIWQso+xDe0Z7wjmz1zd/TBmsxZEmguEuXbLvObVlW8xEnLqkZMWFygN8QqluyQpElLCCQEwnOhOHVQQLu23I7MPWd5SaNi9N32Xk8Lzfu6pDWxlvamOJt5FzVtFPhUaKvVdbIGHBfbsagPnlUO52qkd7gmvBdRYI/12L8XoMhN7sE0AQlv1hNGhXuIIaLG41xYvA2qOg=')
    const [userId, setUserId] = useState('test1')
    const [userName, setUserName] = useState('nihar')

    
    var appID = 399200108;
    var serverSecret = '8da2476318f16304b54c43a721f504da';

    let myMeeting = async (element) => {
        // Create instance object from Kit Token.
        const zp = ZegoUIKitPrebuilt.create(kitToken);
        // start the call
        zp.joinRoom({
          container: element,
          scenario: {
            mode: ZegoUIKitPrebuilt.LiveStreaming,
            config: {
              role,
            },
          },
          sharedLinks,
        });
    };

    const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID,  randomID(5),  randomID(5));
//     ZIM.create({ appID });
//     var zim = ZIM.getInstance();
    
//   useEffect(()=>{
//     try {
//         const newUserDetails = {userName: userName, userID: userId }
//         zim.login(newUserDetails, token)
//             .then(function (res) {
//                 console.log("res", res)
//             })
//             .catch(function (err) {
//                 console.log("err", err)
//             });
//     } catch (error) {
//         console.log("error", error)
//     }

//     zim.on('error', function (zim, errorInfo) {
//         console.log('error', errorInfo.code, errorInfo.message);
//     });
    
//     // Set up and listen for the callback for connection status changes.
//     zim.on('connectionStateChanged', function (zim, { state, event, extendedData }) {
//         console.log('connectionStateChanged', state, event, extendedData);
//         // When SDK logout occurred due to a long-time network disconnection, you will need to log in again. 
//         if (state == 0 && event == 3) {
//             zim.login(userName, token)
//         }
//     });
    
//     // Set up and listen for the callback for receiving one-to-one messages. 
//     zim.on('receivePeerMessage', function (zim, { messageList, fromConversationID }) {
//         console.log('receivePeerMessage', messageList, fromConversationID);
//     });
    
//     // Set up and listen for the callback for token expires.
//     zim.on('tokenWillExpire', function (zim, { second }) {
//         console.log('tokenWillExpire', second);
//         // You can call the renewToken method to renew the token. 
//         // To generate a new Token, refer to the Prerequisites.
//         zim.renewToken(token)
//             .then(function({ token }){
//                 // Renewed successfully.
//             })
//             .catch(function(err){
//                 // Renew failed.
//             })
//     });
//   },[])
  useEffect(() => {
    
    if (localStorage.getItem("OM_Admin_ID") == null) {
      navigate("/om-outline-admin");
      return;
    }
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    if (disable == true) {
      setEntity([]);
    }
  }, [disable]);

//   const handleLogin = () => {
   
// }
  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        {/* <Menu /> */}

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              <a href="javascript:;">Home</a>
            </li>
            <li className="breadcrumb-item active currentPath">
              Live Stream Demo
            </li>
          </ol>
          <h1 className="page-header">Live Stream Demo</h1>
          <div
            className="myCallContainer"
            ref={myMeeting}
            style={{ width: '100vw', height: '100vh' }}
            ></div>
        </div>

        <Footer />
      </div>
    </>
  );
}
