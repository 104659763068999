import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory, useNavigate } from "react-router-dom";
// import env from "react-dotenv";

import { toast } from "react-toastify";
import axios from "axios";
import { D_URL } from "../baseurl";
function Login() {
  const navigate = useNavigate();

  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);

  const InputEvent = (e) => {
    const newLoginInfo = { ...loginInfo };
    // newLoginInfo[e.target.name] = e.target.value;
    if (e.target.name === "email") {
      newLoginInfo[e.target.name] = e.target.value.toLowerCase();
    } else {
      newLoginInfo[e.target.name] = e.target.value;
    }
    setLoginInfo(newLoginInfo);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      setDisable(true);
      const { email, password } = loginInfo;

      const myurl = `${D_URL}/sign-in`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("email", email);
      bodyFormData.append("password", password);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          //console.log('@@', response?.data);
          if (response?.data?.status) {
            localStorage.setItem("OM_Admin_ID",response?.data?.data[0].token);
            axios({
              method: "post",
              url: D_URL+"/get-admin-data",
              data:[],
              headers: { "Content-Type": "application/x-www-form-urlencoded",Authorization:"Barrer "+response?.data?.data[0].token },
            }).then((response)=>{

                let img = response?.data?.data?.image;
                let img1 = "./assets/img/icon/profileIcon.png";
                
                localStorage.setItem("OM_Admin_NAME", response.data.data.name);
                localStorage.setItem("OM_Admin_EMAIL", response.data.data.email);
                localStorage.setItem(
                "OM_Admin_IMAGE",
                response?.data?.data?.image ? img : img1
                );
              navigate(`/dashboard`);
            })

          } else {
            setDisable(false);
            toast.error("!...Your email or password is wrong");
          }
        })
        .catch((error) => {
          console.log("Errors", error);
          toast.error("!...Something went wrong");
          setDisable(false);
        });
    } else {
      setDisable(false);
    }
  };
  
  const validate = () => {
    let input = loginInfo;

    let errors = {};
    let isValid = true;

    if (!input["email"]) {
      isValid = false;
      errors["email_err"] = "Please enter email";
    }
    if (!input["password"]) {
      isValid = false;
      errors["password_err"] = "Please enter password";
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    if (localStorage.getItem("OM_Admin_ID") != null) {
      toast.success(
        `Already login as ${localStorage.getItem("OM_Admin_NAME")}`
      );
      navigate("/dashboard");
    }
    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  return (
    <>
      <div id="page-loader" className="fade show">
        <span className="spinner"></span>
      </div>

      {/* <div className="login-cover">
        <div
          className="login-cover-image"
          style={{
            backgroundImage: "url(assets/img/login-bg/login-bg-17.jpg)",
          }}
          data-id="login-cover-image"
        ></div>
        <div className="login-cover-bg"></div>
      </div> */}

      <div id="page-container" className="fade">
        <div
          className="login login-v2"
          data-pageload-addclassName="animated fadeIn"
        >
          <div className="login-header">
            <div className="brand">
              <img src="/assets/img/logo/logo-admin.svg " style={{height:"80PX",width:"auto",objectFit:"cover"}}/>
              
                <b>OM Outline</b>
             
              <small>Login for Om At Home Admin Panel</small>
            </div>
            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          </div>

          <div className="login-content">
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="form-group m-b-20">
                <input
                  type="text"
                  className="form-control form-control-lg ml-0"
                  placeholder="Email Address"
                  name="email"
                  onChange={InputEvent}
                />
                <div className="text-danger">{errors.email_err}</div>
              </div>
              <div className="form-group m-b-20">
                <input
                  type="password"
                  className="form-control form-control-lg ml-0"
                  placeholder="Password"
                  name="password"
                  onChange={InputEvent}
                />
                <div className="text-danger">{errors.password_err}</div>
              </div>
              <div className="form-group m-b-20">
                <Link
                  to={{
                    pathname: "/forgot-password",
                  }}
                  state={loginInfo.email}
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="login-buttons">
                <button
                  type="submit"
                  className="btn btn-success btn-block btn-lg"
                  disabled={disable}
                >
                  {disable ? "Processing..." : "Sign me in"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
