import { TableHeader, Pagination, Search } from "../Table";
import React, { useState, useEffect, useMemo } from "react";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Footer from "../include/Footer";
import { Dropdown, Table } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import HistoryIcon from "@material-ui/icons/History";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Spinner from "../include/Spinner";
// import env from "react-dotenv";
import { D_URL } from "../baseurl";
import Excel from "exceljs";
// import moment from 'moment';
import { saveAs } from 'file-saver';


export default function List() {
  const location = useLocation();
  const navigate = useNavigate();
  const [entity, setEntity] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [limit, setlimit] = useState(10);
  const [disable, setDisable] = useState(true);
  const [finalArray, setFinalArray] = useState([]);
  const userHeader = [
    {
      name: "Sr. No.",
      field: "id",
      sortable: false,
    },
    {
      name: "Image",
      // field: "",
      sortable: false,
    },
    {
      name: "Name",
      // field: "",
      sortable: false,
    },
    {
      name: "Email",
      // field: "",
      sortable: false,
    },
    {
      name: "PhoneNumber",
      // field: "to",
      sortable: false,
    },
    {
      name: "Status",
      sortable: true,
    },
    {
      name: "Details",
      sortable: false,
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("OM_Admin_ID") == null) {
      navigate("/om-outline-admin");
      return;
    }
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    if (disable == true) {
      setEntity([]);
      getCustomerList();
    }
  }, [disable]);

  const commentsData = useMemo(() => {
    let computedComments = entity;

    if (search) {
      computedComments = computedComments.filter(
        (customers) =>

          customers.firstName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          customers.lastNamet?.toLowerCase()?.includes(search?.toLowerCase()) ||
          customers.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
          customers.mobileno
            ?.toLowerCase()
            ?.includes(search?.toLowerCase()) ||

          customers.status == 0 && "unverified".includes(search?.toLowerCase()) ||
          customers.status == 1 && "verified".includes(search?.toLowerCase()) ||
          customers.status == 2 && "block".includes(search?.toLowerCase())

      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments?.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, sorting, limit, entity]);



  const getCustomerList = () => {
    const myurl = `${D_URL}/get-customer-data`;
    const fd = new FormData();

    fd.append("page", currentPage);
    axios({
      method: "post",
      url: myurl,
      data: fd,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('OM_Admin_ID') },
    })
      .then(async (response) => {
        console?.log("@@", response?.data);
        if (response?.data?.status) {
          let data = await response?.data?.data?.map((e, i) => {
            return { ...e, sr_no: i + 1 };
          });

          setEntity(data);
          setDisable(false);
          let arr = [];
          data.map(elem => {
            arr.push({
              customer_name: elem.firstName + ' ' + elem.lastName,
              email: elem.email,
              mobileno: elem.mobileno
            })
          })
          console.log(arr)
          setFinalArray(arr)
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        console?.log("Errors", error);
      });
  };

  
  const switchStatus = (status) => {
    let el;
    switch (status) {
      case 0:
        el = <span className="text-info">Unverified</span>;
        break;
      case 1:
        el = <span className="text-warning">Verified</span>;
        break;
      case 2:
        el = <span className="text-danger">Blocked</span>;
        break;
      default:
        el = <span></span>;
        break;
    }
    return el;
  };

  const blockCustomer = (id, name, status) => {
    Swal.fire({
      title: status == 2 ? "Unblock" : "Block",
      text: `Are you sure to want to ${status == 2 ? "unblock" : "block"} ${name}`,
      icon: "warning",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: status == 2 ? "Unblock" : "Block",
      showCancelButton: true,
      confirmButtonText: status == 2 ? "Unblock" : "Block",
    }).then((res) => {
      if (res.isConfirmed) {
        const myurl = `${D_URL}/block-customer`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("customer", id);
        bodyFormData.append("status", status);
        axios({
          method: "post",
          url: myurl,
          data: bodyFormData,
          headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('OM_Admin_ID') },
        })
          .then((response) => {
            console?.log("@@", response?.data);
            if (response?.data?.status) {
              setDisable(true);
              toast.success(response?.data?.message);

            } else {
              toast.error(response?.data?.message);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
          });
      }
    });
  };

  const deleteCustomer = (id, name) => {
    Swal.fire({
      title: "Delete",
      text: `Are you sure to want to delete ${name}`,
      icon: "warning",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then(async (res) => {
      if (res.isConfirmed) {
        const myurl = `${D_URL}/delete-customer`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("customer", id);
        axios({
          method: "post",
          url: myurl,
          data: bodyFormData,
          headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('OM_Admin_ID') },
        })
          .then(async (response) => {
            console?.log("@@", response?.data);
            if (response?.data?.status) {
              setDisable(true);
              toast.success(response?.data?.message);

            } else {
              toast.error(response?.data?.message);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
          });
      }
    });
  };


  const Report = async () => {
    // const start_date = new Date(dates.start);
    // const end_date = new Date(dates.end);
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet();
    ws.columns = [
      // { header: 'image', key: 'image' },
      { header: 'Customer Name', key: 'customer_name' },
      { header: 'Email', key: 'email' },
      { header: 'Phone Number', key: 'mobileno' }
    ];
    ws.columns = [{ width: 25 }, { width: 40 }, { width: 20 }]
    ws.getCell('A1').font = { bold: true };
    ws.getCell('B1').font = { bold: true };
    ws.getCell('C1').font = { bold: true };
    // ws.getCell('D1').font = { bold: true };

    ws.addRows(finalArray);
    const buf = await wb.xlsx.writeBuffer()
    saveAs(new Blob([buf]), `Customer Data List.xlsx`)

    return <div>Report generated successfully</div>;
  }


  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              <a href="javascript:;">Home</a>
            </li>
            <li className="breadcrumb-item active currentPath">
              User Management
            </li>
          </ol>
          <h1 className="page-header">User Management</h1>
          {disable == false ? (
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "20px",
                marginTop: "10px",
              }}
            >
              <div className="row w-100">
                <div className="mb-3 col-12 text-center">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-sm-3 col-12 mb-3">
                      <div className="ml-0">
                        <div className="d-flex">
                          <h5 className="mt-2 mr-1">Search: </h5>
                          <Search
                            onSearch={(value) => {
                              setSearch(value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-3 col-lg-3 col-sm-3 col-12 d-flex">
                      <button type="button" className="btn btn-success" >Download</button>
                    </div> */}
                    <div className="col-xl-3 col-lg-3 col-sm-3 col-12 d-flex justify-content-end mb-3">
                      <div
                        style={{
                          color: "black",
                          fontSize: "12px",
                          fontWeight: "300",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                        className="align-self-center"
                      >
                        <h5 className="mt-2 mr-1">Total Users : {entity?.length} </h5>
                        {/* <input type="text" className="form-control" value="number" style:  /> */}
                      </div>
                    </div>

                    <div className="col d-flex justify-content-end mb-3">
                      {entity.length > 0 ? <button type="button" className="btn btn-success mx-5" onClick={Report}>Download Excel</button> : ""}

                      <div
                        style={{
                          color: "black",
                          fontSize: "12px",
                          fontWeight: "300",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                        className="align-self-center"
                      >
                        <b>Rows per page :&nbsp;</b>
                      </div>
                      <div className="align-self-center">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            id="dropdown-basic"
                            style={{
                              cursor: "auto",
                              backgroundColor: "white",
                              borderColor: "#d5dbe0",
                              paddingBottom: "3px",
                              paddingTop: "3px",
                            }}
                          >
                            {limit}&nbsp;<i className="fa fa-caret-down"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {limit !== 10 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(10);
                                  }}
                                >
                                  10
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 20 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(20);
                                  }}
                                >
                                  20
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 30 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(30);
                                  }}
                                >
                                  30
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 50 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(50);
                                  }}
                                >
                                  50
                                </Dropdown.Item>
                              </>
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive">
                        <Table striped bordered hover>
                          <thead>
                            {/* <TableHeader
                              headers={userHeader}
                              onSorting={(field, order) =>
                                setSorting({ field, order })
                              }
                            /> */}
                            <tr>
                              <th>Sr no</th>
                              <th>Image</th>
                              <th>Customer's Name</th>
                              <th>Email</th>
                              <th>Phone No</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {commentsData.map((e, i) => (
                              <tr>
                                <>
                                  <td>{e?.sr_no}</td>
                                  <td>
                                    {/* {e?.image?.match("undefined") !=
                                      "undefined" ? (
                                      e?.image != null ? (
                                        <img className="img-round"
                                          src={e?.image}
                                          width="30px"
                                          height="30px"
                                          alt="Customer"
                                        />
                                      ) : (
                                        <img className="img-round"
                                          src="./assets/img/icon/customer.png"
                                          width="30px"
                                          height="30px"
                                          alt="users"
                                        />
                                      )
                                    ) : 
                                    (
                                      <img className="img-round"
                                        src="./assets/img/icon/customer.png"
                                        width="30px"
                                        height="30px"
                                        alt="users"
                                      />
                                    )
                                    } */}
                                    {/* chnage  */}
                                    {e?.image.length > 0 ?
                                      <img className="img-round"
                                        src={e?.image}
                                        width="30px"
                                        height="30px"
                                        alt="Customer"
                                      />
                                      : <i class="fas fa-user"></i>
                                    }
                                  </td>
                                  {/* <td>{e?.first_name + " " + e?.last_name}</td> */}

                                  <td>{e?.firstName + " " + e?.lastName}</td>
                                  <td>{e?.email}</td>
                                  <td>{e?.mobileno}</td>
                                  <td>{switchStatus(e?.status)}</td>
                                  {/* <td>{e?.license_plate}</td>
                                  <td>{e?.preferred_delivery_radius}</td> */}
                                  <td>

                                    {/* <span className="text text-primary" style={{ cursor: "pointer" }} onClick={() =>navigate("/customer-details", {state: e,})}><u>View details</u></span><br/>
                                    {e?.status==1?(
                                      <><span className="text text-success" style={{ cursor: "pointer" }} onClick={() =>approveCustomer(e._id,e.name)}><u>Approve</u></span><br/></>
                                    ):""} */}

                                    {e?.status != 2 ? (
                                      <><span className="text text-danger" style={{ cursor: "pointer" }} onClick={() => blockCustomer(e._id, e.firstName + " " + e.lastName, e.status)}><u>Block</u></span><br /></>
                                    ) : (
                                      <>
                                        <span className="text text-success" style={{ cursor: "pointer" }} onClick={() => blockCustomer(e._id, e.firstName + " " + e.lastName, e.status)}>
                                          <u>Unblock</u>
                                        </span><br />
                                      </>
                                    )}

                                    <span className="text text-secondary" style={{ cursor: "pointer" }} onClick={() => deleteCustomer(e._id, e.firstName + " " + e.lastName)}><u>Delete</u></span>

                                  </td>
                                </>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {commentsData.length === 0 ? (
                          <div className="row d-flex justify-content-center w-100">
                            <div className="mx-auto  d-flex justify-content-center w-100">
                              <img
                                src="./assets/img/icon/no-location.png"
                                className="form-img__img-preview"
                                style={{ width: "100px", height: "100px" }}
                                alt=""
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                    style={{
                      overflowX: "auto",
                    }}
                  >
                    <Pagination
                      total={totalItems}
                      itemsPerPage={limit}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>

        <Footer />
      </div>
    </>
  );
}
