import React, { useState, useEffect } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Footer from "../include/Footer";
import axios from "axios";
// import env from "react-dotenv";
import { D_URL } from "../baseurl";


import CropEasy from '../CropImage/CropEasy';

export default function AddEvent() {
  const [change, setChange] = useState(true);
  const [guruList, setGuruList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, [change]);

  // useEffect(() => {
  //   const fillGuruList = ()=>{
  //     let guruOptionArr = [];
  //     guruList.map((guru)=>{
  //       guruOptionArr.push()
  //     });

  //     return guruOptionArr;
  //   }
  // }, [guruList]);

  useEffect(() => {
    if (localStorage.getItem("OM_Admin_ID") == null) {
      navigate("/om-outline-admin");
      return;
    }
    let myurl = `${D_URL}/get-guru-name-list`;
    var bodyFormData = new URLSearchParams();

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: 'Barrer ' + localStorage.getItem("OM_Admin_ID") },
    })
      .then((response) => {
        if (response?.data?.status == true) {

          setGuruList(response?.data?.data);

        } else {
          console.log("===Error===");
          //console.log('===Error===', response?.data);
          setDisable(false);
          setPictureName({});
          setIsPicUpload(false);
          toast.success("Something went wrong");
        }
      })
      .catch((error) => {
        //console.log('Errors', error);
        console.log("Errors");
        toast.error("Something went wrong");
        setPictureName({});
        setDisable(false);
        setIsPicUpload(false);
      });

  }, []);

  const [img, setImg] = useState({
    src: "",
    alt: "",
  });
  const [eventFromData, seteventFromData] = useState({});
  const [isPicUpload, setIsPicUpload] = useState(false);
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [pictureName, setPictureName] = useState("");

  //console.log('@@', localStorage.getItem('OM_Admin_IMAGE'));
  //console.log('@@', localStorage.getItem('OM_Admin_IMAGE')?.split('/')?.pop());
  // const handleImg = (e) => {
  //   if (e?.target?.files[0]) {
  //     var image = new Image();
  //     image.src = URL.createObjectURL(e?.target?.files[0]);
  //     image.onload = function () {

  //       setImg({
  //         src: URL.createObjectURL(e?.target?.files[0]),
  //         alt: e?.target?.files[0].name,
  //       });
  //       setDisable(true);
  //       setIsPicUpload(true);
  //       const myurl = `${process.env.REACT_APP_BASE_URL}api/v1/upload-image`;
  //       var bodyFormData = new FormData();
  //       bodyFormData.append("image", e?.target?.files[0]);

  //       axios({
  //         method: "post",
  //         url: myurl,
  //         data: bodyFormData,
  //       })

  //         .then((result) => {
  //           console.log("=====", result);

  //           if (result?.data?.status == true) {
  //             //console.log('Success:=====',result?.data?.data?.filepath_url);
  //             setPictureName(result?.data?.data?.url);
  //             setDisable(false);
  //           } else {
  //             console.log("Error:=====");
  //             // console.log('Error:=====', result?.data);
  //             setIsPicUpload(false);
  //             setPictureName("");
  //             setDisable(false);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           //console.log('Errors', error);
  //           setIsPicUpload(false);
  //           setPictureName("");
  //           setDisable(false);
  //         });
  //     }
  //   }
  // };

  // crop image

  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  function blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
  }

  const croppedImage = (file, url) => {
    var myFile = blobToFile(file, file.name);
    console.log(myFile, "files")
    // setImage(myFile);
    setShowImage();
    setOpenCrop(false);

    setDisable(true);

    const myurl = `${process.env.REACT_APP_BASE_URL}api/v1/upload-image`;
    const bodyFormData = new FormData();
    bodyFormData.append('image', myFile);

    axios({
      method: 'post',
      url: myurl,
      data: bodyFormData,
    })
      .then((result) => {
        console.log(result);

        if (result?.data?.status == true) {
          console.log('Success:=====', result?.data?.data?.filepath_url);
          seteventFromData({ ...eventFromData, image: result?.data?.data?.url });
          setDisable(false);
        } else {
          console.log("Error:=====");
          console.log('Error:=====', result?.data);
          setIsPicUpload(false);
          setPictureName("");
          setDisable(false);
        }

      })
      .catch((error) => {
        console.log(error, "$$-error");
        setIsPicUpload(false);
        setPictureName("");
        setDisable(false);
      })
  }


  const handleImg = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      setImage(image);
      setShowImage(URL.createObjectURL(image));
      setOpenCrop(true);
      console.log(showImage, "images");
    }
  }


  const InputEvent = (e) => {
    const neweventFromData = { ...eventFromData };
    neweventFromData[e.target.name] = e.target.value;
    seteventFromData(neweventFromData);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      setDisable(true);

      const { name } = eventFromData;
      const myurl = `${D_URL}/add-event`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("guru_id", eventFromData.guru);
      bodyFormData.append("service_id", "63a6dbf7192cffea1728ae6f");
      bodyFormData.append("program_title", eventFromData.title);
      bodyFormData.append("description", eventFromData.description);
      bodyFormData.append("program_date_time", eventFromData.program_start_datetime);
      bodyFormData.append("program_end_date_time", eventFromData.program_end_date_time);
      bodyFormData.append("image", eventFromData.image);
      bodyFormData.append("repeate_type", eventFromData.repeate_type)
      // bodyFormData.append("type", "1")
      if (eventFromData?.repeate_event_date_time) {
        bodyFormData.append("repeate_event_date_time", eventFromData.repeate_event_date_time);
      }



      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: 'Barrer ' + localStorage.getItem("OM_Admin_ID") },
      })
        .then((response) => {
          if (response?.data?.status == true) {

            setDisable(false);
            console.log("===SUCCESSFUL===", response?.data);
            toast.success("Class added successfully");
            navigate('/classes-list');

          } else {
            console.log("===Error===");
            toast.error(response?.data?.message)
            //console.log('===Error===', response?.data);
            setDisable(false);
            setPictureName({});
            setIsPicUpload(false);
          }
        })
        .catch((error) => {
          //console.log('Errors', error);
          console.log("Errors");
          toast.error("Something went wrong");
          setPictureName({});
          setDisable(false);
          setIsPicUpload(false);
        });
    }
    setChange(!change);
  };


  const validate = () => {
    let input = eventFromData;

    let errors = {};
    let isValid = true;

    if (!input["title"]) {
      isValid = false;
      errors["title_err"] = "Please enter title";
    }
    if (!input["guru"] || input["guru"] == "0") {
      isValid = false;
      errors["guru_err"] = "Please select guru";
    }
    if (!input["description"]) {
      isValid = false;
      errors["description_err"] = "Please enter description";
    }

    if (!input["program_start_datetime"]) {
      isValid = false;
      errors["program_start_datetime_err"] = "Please enter start date";
    }

    if (!input["program_end_date_time"]) {
      isValid = false;
      errors["program_end_date_time_err"] = "Please enter end date";
    } else {
      const endDate = new Date(input["program_end_date_time"]);
      const startDate = new Date(input["program_start_datetime"]);
      const timeDifferenceInMs = endDate.getTime() - startDate.getTime();
      const timeDifferenceInMinutes = timeDifferenceInMs / (1000 * 60); // convert to minutes

      if (timeDifferenceInMinutes < 5 || timeDifferenceInMinutes > 360) {
        isValid = false;
        errors["program_end_date_time_err"] = "Please select a date and time that is at least 5 minutes and no more than 6 hours after the start date and time.";
      }
    }
    // if (!input["repeate_event_date_time"]) {
    //   isValid = false;
    //   errors["repeate_event_date_time_err"] = "Please enter repeat event date";
    // }

    // if (!setIsPicUpload || pictureName == "") {
    //   isValid = false;
    //   errors["img_err"] = "Please select image";
    // }
    if (!input["image"]) {
      isValid = false;
      errors["img_err"] = "Please select image";
    }

    // if (!input["repeate_type"]) {
    //   isValid = false;
    //   errors["repeate_type_err"] = "Please select the repeate type"
    // }

    setErrors(errors);
    return isValid;
  };


  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/classes-list">
                <span className="basePath">Classes</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Add</li>
          </ol>
          <h1 className="page-header">Add Classes</h1>

          <div className="row">
            <div className="col-xl-12 p-5">
              <div
                className="card "
                style={{
                  height: "auto",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >

                <form onSubmit={(e) => submitHandler(e)} className="profileForm row" >
                  <div className="col-sm-6 mb-3">
                    <label for="exampleInputEmail1" className="form-label"> Title : </label>
                    <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter title here.." name="title"
                      value={eventFromData.title} onChange={InputEvent} />
                    <div className="text-danger">{errors.title_err}</div>
                  </div>

                  <div className="col-sm-6 mb-3">
                    <label for="exampleInputEmail1" className="form-label"> Select Guru: </label>
                    {/* <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter name here.." name="name"
                      value={eventFromData.name} onChange={InputEvent} /> */}
                    <select className="form-control ml-0" name="guru"
                      value={eventFromData.guru} onChange={InputEvent}>
                      <option value="0">Select Guru</option>
                      {
                        guruList.map((guru) => {
                          return <option value={guru._id}>{guru.name + " - (" + guru.email + ")"}</option>
                        })
                      }
                    </select>

                    <div className="text-danger">{errors.guru_err}</div>
                  </div>

                  <div className="col-sm-6 mb-3">
                    <label for="exampleInputEmail1" className="form-label"> Start Date and time : </label>
                    <input type="datetime-local" className="form-control ml-0" id="exampleInputName" placeholder="Enter title here.." name="program_start_datetime"
                      value={eventFromData.start_datetime} onChange={InputEvent} />
                    <div className="text-danger">{errors.program_start_datetime_err}</div>

                    <label for="exampleInputEmail1" className="form-label"> End Date and time : </label>
                    <input type="datetime-local" className="form-control ml-0" id="exampleInputName" placeholder="Enter title here.." name="program_end_date_time"
                      value={eventFromData.program_end_date_time} onChange={InputEvent} />
                    <div className="text-danger">{errors.program_end_date_time_err}</div>
                  </div>



                  <div className="col-sm-6 mb-3">
                    <label for="exampleInputEmail1" className="form-label"> Description : </label>
                    <textarea className="form-control ml-0" id="exampleInputName" placeholder="Enter description here.." name="description"
                      onChange={InputEvent} value={eventFromData.description} rows="3"></textarea>
                    <div className="text-danger">{errors.description_err}</div>
                  </div>

                  <div className="col-sm-6 mb-3">
                    <label for="exampleInputEmail1" className="form-label">Repeat class date </label>
                    <input type="date" className="form-control ml-0" id="exampleInputName" placeholder="Enter title here.." name="repeate_event_date_time"
                      value={eventFromData.repeate_event_date_time} onChange={InputEvent} />
                    <div className="text-danger">{errors.repeate_event_date_time_err}</div>
                  </div>

                  <div className="col-sm-6 mb-3">
                    <label for="exampleInputEmail1" className="form-label">Repeat class duration</label>
                    <select className="form-control ml-0" name="repeate_type"
                      value={eventFromData.repeate_type} onChange={InputEvent}>
                      <option value="0">Select Repeate</option>
                      <option value="1">Daily</option>
                      <option value="2">Weekly</option>
                      <option value="3">Monthly</option>
                    </select>
                    <div className="text-danger">{errors.repeate_type_err}</div>
                  </div>


                  <div className="col-sm-6 mb-3">
                    <label for="exampleInputImage" className="form-label">Image: </label>

                    <input
                      type="file"
                      className="form-control imgInput ml-0 mt-1"
                      id="exampleInputImage"
                      onChange={handleImg}
                      accept="image/png, image/gif, image/jpeg"
                    />
                    <CropEasy
                      photoURL={showImage}
                      file={image}
                      activeModal={openCrop}
                      // setActiveModal={()}
                      croppedImage={croppedImage}
                    />
                    <div style={{ textAlign: "center" }}>
                      {eventFromData.image ? <img src={eventFromData.image} style={{ width: '300px', height: '130px', objectFit: 'cover', borderRadius: '10px', marginTop: "10px" }} /> : ''}

                    </div>
                    <div className="text-danger">{errors.img_err}</div>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label for="exampleInputImage" className="form-label">&nbsp;</label>
                    <div className="ml-0 mt-1">
                      <button type="submit" className="btn  btn-success mr-2" disabled={disable}>
                        {disable ? "Processing..." : "Submit"}</button>
                      <button type="reset" className="btn btn-dark" value="Reset"
                        onClick={(e) => {
                          setIsPicUpload(false);
                          setImg({ src: "", alt: "" });
                          setChange(!change);
                        }}
                      >Reset</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
