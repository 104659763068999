import {
  Routes,
  Route, Navigate, useNavigate
  // Link
} from "react-router-dom";
import Login from "./components/login/login";
import ForgotPass from "./components/login/forgotPass";
import Profile from "./components/Profile/Profile";
import Dashboard from "./components/dashboard/dashboard";
import GuruList from "./components/guru/List";
import CustomerList from "./components/customer/List";
import SchoolCompanyList from "./components/schoolCompany/List";
import GuruDetails from "./components/guru/GuruDetails.jsx";
import EditGuruDetails from "./components/guru/EditGuruDetails.jsx";
import DonationList from "./components/donation/List"
import EventList from "./components/events/List";
import AddEvent from "./components/events/AddEvent.jsx";
import EditEvent from "./components/events/EditEvent";
import Subscription from "./components/Subscription/Subscription";
import SubscriptionPlan from "./components/SubscriptionPlan/AddSubscriptionPlan";
import SubscriptionList from "./components/SubscriptionPlan/SubscriptionList";
import EditSubscription from "./components/SubscriptionPlan/EditSubscription";
import Contact from "./components/contatctUs/Contact";
import LiveStearmDemo from "./components/LiveStream/demo.jsx";
import CourseList from "./components/courses/List"
import AddCourses from "./components/courses/AddCourses";
import EditCourse from "./components/courses/EditCourse";
import GetVideoList from "./components/courses/Video/List";
import AddVideo from "./components/courses/Video/AddVideo";
import EditVideo from "./components/courses/Video/EditVideo";
import PastVideo from "./components/events/PastVideo";
import Cms from "./components/Cms";
import List from "./components/Blogs/List";
import AddBlog from "./components/Blogs/AddBlog";
import ClassDescription from "./components/ClassDescription/ClassDescription";
import AddDescription from './components/ClassDescription/AddDescription'
// import HomeList from "./components/Home/HomeList";
import ManageHome from "./components/Home/ManageHome";

// import Notfound from "./components/errors/404";

function App() {
  const navigate = useNavigate();

  return (
    <>
      <Routes>
        <Route path="/om-outline-admin" exact element={<Login />} />
        <Route path="/forgot-password" exact element={<ForgotPass />} />
        <Route path="/admin-profile" exact element={<Profile />} />

        <Route path="/dashboard" exact element={<Dashboard />} />

        <Route path="/guide-list" exact element={<GuruList />} />
        <Route path="/guru-details" exact element={<GuruDetails />} />
        <Route path="/edit-guru-details" exact element={<EditGuruDetails />} />

        <Route path="/user-list" exact element={<CustomerList />} />
        <Route path="/group-list" exact element={<SchoolCompanyList />} />

        <Route path="/classes-list" exact element={<EventList />} />
        <Route path="/add-classes" exact element={<AddEvent />} />
        <Route path="/edit-classes" exact element={<EditEvent />} />

        <Route path="/view-past-recording" exact element={<PastVideo />} />

        <Route path="/contact-us-list" exact element={<Contact />} />

        {/* <Route path="/donation-list" exact element={<DonationList />} /> */}

        <Route path="/add-subscription-plan" exact element={<SubscriptionPlan />} />
        <Route path="/edit-subscription-plan" exact element={<EditSubscription />} />
        <Route path="/subscription-plan-list" exact element={<SubscriptionList />} />

        <Route path="/subscription-list" exact element={<Subscription />} />
        <Route path="/live-stream-demo" exact element={<LiveStearmDemo />} />

        <Route path="/classes-description-list" exact element={<ClassDescription />} />
        <Route path="/manage-classes-description" exact element={<AddDescription />} />

        {/* <Route path="/guru-details" exact element={<GuruDetails />} /> */}

        {/* courses */}
        <Route path="/courses-list" exact element={<CourseList />} />
        <Route path="/add-courses" exact element={<AddCourses />} />
        <Route path="/edit-courses" exact element={<EditCourse />} />

        {/* videos */}
        <Route path="/video-list" exact element={<GetVideoList />} />
        <Route path="/add-video" exact element={<AddVideo />} />
        <Route path="/edit-video" exact element={<EditVideo />} />

        {/* Blogs */}
        <Route path="/blogs-list" exact element={<List />} />
        <Route path="/manage-blog" exact element={<AddBlog />} />

        {/* HomePage */}
        {/* <Route path="/home-desription-list" exact element={<HomeList />} /> */}
        <Route path="/manage-home-description" exact element={<ManageHome />} />



        <Route path="/cms-list" exact element={<Cms />} />

        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
        {/* <Route path="*" exact element={<Notfound/>}  status={404}/> */}
      </Routes>

    </>
  );
}

export default App;
