import React, { useState, useEffect, useMemo } from 'react'
import Footer from '../include/Footer';
import Loader from '../include/Loader';
import Menu from '../include/Menu';
import Spinner from '../include/Spinner';
import { toast } from "react-toastify";
import { Dropdown, Table } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Pagination, Search } from "../Table";
import moment from 'moment';
import axios from 'axios';
import Swal from "sweetalert2";
import { D_URL } from "../baseurl";

const List = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [entity, setEntity] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);

    useEffect(() => {
        if (localStorage.getItem("OM_Admin_ID") == null) {
            navigate("/om-outline-admin");
            return;
        }
        document.getElementById("page-loader").style.display = "none";
        var element = document.getElementById("page-container");
        element.classList.add("show");
        if (disable == true) {
            setEntity([]);
            getBlog();
        }
    }, [disable]);




    const blogData = useMemo(() => {
        let computedPlan = entity;
        if (search) {
            computedPlan = computedPlan.filter(
                (events) =>
                    events.description?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    events.title?.toLowerCase()?.includes(search?.toLowerCase())
            );
        }
        setTotalItems(computedPlan?.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedPlan = computedPlan.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedPlan?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [entity, currentPage, search, sorting, limit])


    const getBlog = () => {

        const myurl = `${D_URL}/get-blogs`;
        // const fd = new FormData();

        // fd.append("page", currentPage);
        axios({
            method: "get",
            url: myurl,
            // data: fd,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('OM_Admin_ID') },
        }).then(async (response) => {
            console?.log("@@", response?.data);
            if (response?.data?.status) {
                let data = await response?.data?.data?.map((e, i) => {
                    return { ...e, sr_no: i + 1 };
                });
                setEntity(data);
                console.log(data, "&&")
                setDisable(false);
            } else {
                toast.error("Something went wrong");
            }
        }).catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
        });
    }




    const deleteBlog = (id, title) => {
        Swal.fire({
            title: "Delete",
            text: `Are you sure to want to delete ${title}`,
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then(async (res) => {
            if (res.isConfirmed) {
                const myurl = `${D_URL}/delete-blog`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("id", id);
                axios({
                    method: "post",
                    url: myurl,
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('OM_Admin_ID') },
                })
                    .then(async (response) => {
                        console?.log("@@", response?.data);
                        if (response?.data?.status) {
                            setDisable(true);
                            toast.success(response?.data?.message);

                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };



    return (
        <div>
            <>
                <Loader />

                <div
                    id="page-container"
                    className="fade page-sidebar-fixed page-header-fixed">

                    <Menu />

                    <div id="content" className="content">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item basePath ">
                                <a href="javascript:;">Home</a>
                            </li>
                            <li className="breadcrumb-item active currentPath">
                                Blogs Management
                            </li>
                        </ol>
                        <h1 className="page-header">Blogs Management</h1>
                        {disable == false ? (
                            <div
                                style={{
                                    backgroundColor: "white",
                                    padding: "20px",
                                    borderRadius: "20px",
                                    marginTop: "10px",
                                }}
                            >
                                <div className="row w-100">
                                    <div className="mb-3 col-12 text-center">
                                        <Link to="/manage-blog" className="btn btn-primary mb-3" style={{ float: "right", float: "right", padding: "3px 20px", fontSize: "16px" }}>Add Blogs</Link>
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-sm-3 col-12 mb-3">
                                                <div className="ml-0">
                                                    <div className="d-flex">
                                                        <h5 className="mt-2 mr-1">Search: </h5>
                                                        <Search
                                                            onSearch={(value) => {
                                                                setSearch(value);
                                                                setCurrentPage(1);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: "12px",
                                                        fontWeight: "300",
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                    className="align-self-center"
                                                >
                                                    <h5 className="mt-2 mr-1">Total Blogs : {entity?.length} </h5>
                                                </div>
                                            </div>
                                            <div className="col d-flex justify-content-end mb-3">
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: "12px",
                                                        fontWeight: "300",
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                    className="align-self-center"
                                                >
                                                    <b>Rows per page :&nbsp;</b>
                                                </div>
                                                <div className="align-self-center">
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="none"
                                                            id="dropdown-basic"
                                                            style={{
                                                                cursor: "auto",
                                                                backgroundColor: "white",
                                                                borderColor: "#d5dbe0",
                                                                paddingBottom: "3px",
                                                                paddingTop: "3px",
                                                            }}
                                                        >
                                                            {limit}&nbsp;<i className="fa fa-caret-down"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {limit !== 10 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(10);
                                                                        }}
                                                                    >
                                                                        10
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 20 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(20);
                                                                        }}
                                                                    >
                                                                        20
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 30 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(30);
                                                                        }}
                                                                    >
                                                                        30
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 50 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(50);
                                                                        }}
                                                                    >
                                                                        50
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row ">
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    <Table striped bordered hover>
                                                        <thead>

                                                            <tr>
                                                                <th>Sr no</th>
                                                                <th>Image</th>
                                                                <th>Title</th>
                                                                <th>Description</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {blogData?.map((e, i) => (
                                                                <tr>
                                                                    <>
                                                                        <td>{e?.sr_no}</td>
                                                                        <td>
                                                                            {e?.image?.match("undefined") !=
                                                                                "undefined" ? (
                                                                                e?.image != null ? (
                                                                                    <img className="img-round"
                                                                                        src={e?.image}
                                                                                        style={{ objectFit: "cover", height: "60px", width: "auto" }}
                                                                                        alt="Blogs"
                                                                                    />
                                                                                ) : (
                                                                                    <img className="img-round"
                                                                                        src="./assets/img/icon/customer.png"
                                                                                        style={{ objectFit: "cover", height: "60px", width: "auto" }}
                                                                                        alt="blogs"
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                <img className="img-round"
                                                                                    src="./assets/img/icon/customer.png"
                                                                                    style={{ objectFit: "cover", height: "60px", width: "auto" }}
                                                                                    alt="blogs"
                                                                                />
                                                                            )}
                                                                        </td>
                                                                        <td>{e?.title}</td>
                                                                        <td>{e?.description?.length > 50 ? e.description.slice(0, 50) + '...' : e.description || ""}</td>
                                                                        <td>
                                                                            <span className="text text-info" style={{ cursor: "pointer" }} onClick={() => navigate("/manage-blog", { state: e })}><u>Edit</u></span><br />
                                                                            <span className="text text-secondary" style={{ cursor: "pointer" }} onClick={() => deleteBlog(e._id, e?.title)}><u>Delete</u></span>
                                                                        </td>
                                                                    </>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    {blogData?.length === 0 ? (
                                                        <div className="row d-flex justify-content-center w-100">
                                                            <div className="mx-auto  d-flex justify-content-center w-100">
                                                                <img
                                                                    src="./assets/img/icon/no-location.png"
                                                                    className="form-img__img-preview"
                                                                    style={{ width: "100px", height: "100px" }}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                                            style={{
                                                overflowX: "auto",
                                            }}
                                        >
                                            <Pagination
                                                total={totalItems}
                                                itemsPerPage={limit}
                                                currentPage={currentPage}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </div>

                    <Footer />
                </div>
            </>

        </div>
    )
}

export default List