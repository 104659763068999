import React from 'react';

export default function ColDetail(props) {
  return (
    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 restaurantDetail">
      <span className="restaurant_heading">{props?.title}</span>
      <br />
      <span className="restaurant_data">
        {props?.data ? props?.data : 'N/A'}
      </span>
    </div>
  );
}
