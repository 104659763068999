import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify";
import { D_URL } from "../../baseurl";
import Footer from '../../include/Footer';
import Loader from '../../include/Loader';
import Menu from '../../include/Menu';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Dropdown, Table } from "react-bootstrap";


const AddVideo = () => {

    const [change, setChange] = useState(true);
    const [videos, setVideos] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const videoRef = useRef();
    const imgRef = useRef();

    const data = location.state
    const dataTwo = location.state;

    useEffect(() => {
        document.getElementById("page-loader").style.display = "none";
        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, [change]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [videoName, setVideoName] = useState('');
    const [guruName, setGuruName] = useState('');
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);

    // const [video, setVideo] = useState({ src: '', name: '' });
    const [isVideoUpload, setIsVideoUpload] = useState(false);
    const [videoURL, setVideoURL] = useState('');
    const [videoKey, setVideoKey] = useState('');
    const [videoDuration, setVideoDuration] = useState(0);

    const handleVideo = (e) => {
        if (e?.target?.files[0].type === 'video/mp4' || e?.target?.files[0].type === 'video/x-matroska') {
            // setVideo({
            //     src: URL.createObjectURL(e?.target?.files[0]),
            //     name: e?.target?.files[0].name,
            // });
            setDisableVid(true);
            setIsVideoUpload(true);
            const myurl = `${D_URL}/upload-video`;
            const bodyFormData = new FormData();
            bodyFormData.append('image', e?.target?.files[0]);

            axios({
                method: 'post',
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: 'Barrer ' + localStorage.getItem("OM_Admin_ID") },
            })
                .then((result) => {
                    console.log(result?.data)
                    if (result?.data?.status === true) {
                        const videoElement = document.createElement('video');
                        videoElement.src = URL.createObjectURL(e?.target?.files[0]);
                        videoElement.addEventListener('loadedmetadata', () => {
                            setVideoDuration(videoElement.duration)
                        })
                        setVideoURL(result?.data?.data?.url);
                        setVideoKey(result?.data?.data?.key)
                        toast.success(result?.data?.message)
                        setDisableVid(false);
                    } else {
                        console.log('Error:=====', result);
                        toast.success(result.data.message)
                        setIsVideoUpload(false);
                        setVideoURL('');
                        setVideoKey('')
                        setDisableVid(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsVideoUpload(false);
                    setVideoURL('');
                    setVideoKey('')
                    setDisableVid(false);
                });
        }
    };

    const [pictureName, setPictureName] = useState("");
    const [isPicUpload, setIsPicUpload] = useState(false);
    const [img, setImg] = useState({
        src: "",
        alt: "",
    });

    const [disableImg, setDisableImg] = useState(false)
    const [disableVid, setDisableVid] = useState(false)

    const handleImg = (e) => {
        if (e?.target?.files[0]) {
            var image = new Image();
            image.src = URL.createObjectURL(e?.target?.files[0]);
            image.onload = function () {
                if (this.width > 1000 || this.height > 1000) {
                    toast.error('Image should be under 1000 * 1000 resolution')
                } else {
                    // setImg({
                    //     src: URL.createObjectURL(e?.target?.files[0]),
                    //     alt: e?.target?.files[0].name,
                    // });
                    setDisableImg(true);
                    setIsPicUpload(true);
                    const myurl = `${process.env.REACT_APP_BASE_URL}api/v1/upload-image`;
                    var bodyFormData = new FormData();
                    bodyFormData.append("image", e?.target?.files[0]);

                    axios({
                        method: "post",
                        url: myurl,
                        data: bodyFormData,
                    })

                        .then((result) => {
                            console.log("=====", result);

                            if (result?.data?.status == true) {
                                //console.log('Success:=====',result?.data?.data?.filepath_url);
                                setPictureName(result?.data?.data?.url);
                                setDisableImg(false);
                            } else {
                                console.log("Error:=====");
                                // console.log('Error:=====', result?.data);
                                setIsPicUpload(false);
                                setPictureName("");
                                setDisableImg(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            setIsPicUpload(false);
                            setPictureName("");
                            setDisableImg(false);
                        });
                }
            }
        }
    };

    const handleDelete = (url) => {
        setVideos(videos.filter(item => item.video_url !== url))
    }

    const handleSaveModal = async (e) => {
        e.preventDefault();
        if (validate()) {
            setVideos([
                ...videos,
                {
                    name: videoName,
                    video_url: videoURL,
                    image: pictureName,
                    video_key: videoKey,
                    duration: videoDuration
                }
            ]);
            setVideoName('');
            setVideoDuration(0);
            setVideoURL('');
            setVideoKey('');
            setPictureName('');
            setShow(false);
            videoRef.current.value = "";
            imgRef.current.value = "";
        }
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if (validateTwo()) {
            setDisable(true);

            var bodyFormData = {
                "course_id": data,
                "video_details": videos,
                "guru_name": guruName
            }
            const myurl = `${D_URL}/add-course-video`;

            // var bodyFormData = new URLSearchParams();
            // bodyFormData.append("name", videoData.name);
            // bodyFormData.append("course_id", data);
            // bodyFormData.append("video_url", videoURL);
            // bodyFormData.append("image", pictureName);
            // bodyFormData.append("guru_name", guruName)
            // bodyFormData.append("video_details", JSON.stringify(videos))
            // bodyFormData.append("video_key", videoKey);
            // bodyFormData.append("duration", videoDuration)

            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/json", Authorization: 'Barrer ' + localStorage.getItem("OM_Admin_ID") },
            })
                .then((response) => {
                    console.log(response.data)
                    if (response?.data?.status === true) {

                        setDisable(false);
                        console.log("===SUCCESSFUL===", response?.data);
                        toast.success("Video added successfully");
                        navigate('/courses-list');

                    } else {
                        console.log("===Error===");
                        // toast.error(response?.data?.message)
                        //console.log('===Error===', response?.data);
                        setDisable(false);
                        setVideoURL({});
                        setIsVideoUpload(false);
                    }
                })
                .catch((error) => {
                    //console.log('Errors', error);
                    console.log("Errors");
                    toast.error("Something went wrong");
                    setVideoURL({});
                    setDisable(false);
                    setIsVideoUpload(false);
                });
        }
        setChange(!change);
    };

    const validateTwo = () => {

        let errors = {};
        let isValid = true;

        if (!guruName) {
            isValid = false;
            console.log(guruName)
            errors["guruName_err"] = "Please enter guru";
        }

        setErrors(errors);
        return isValid;
    }

    const validate = () => {

        let errors = {};
        let isValid = true;


        if (!videoName) {
            isValid = false;
            errors["name_err"] = "Please enter name";
        }

        // if (!videoURL || setIsVideoUpload === "") {
        //     isValid = false;
        //     errors["video_err"] = "Please select a video file";
        // } else if (videoURL.type === 'video/mp4' || videoURL.type === 'video/x-matroska') {
        //     console.log("API CALL")
        //     isValid = false;
        //     errors["video_err"] = "Select the video file"
        // }

        if (!videoURL) {
            isValid = false;
            errors["video_err"] = "Please select a video file";
        }

        if (!setIsPicUpload || pictureName === "") {
            isValid = false;
            errors["img_err"] = "Please select image";
        }

        setErrors(errors);
        return isValid;
    }

    return (
        <>
            <Loader />

            <div
                id="page-container"
                className="fade page-sidebar-fixed page-header-fixed"
            >
                <Menu />

                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard">
                                <span className="basePath">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink to="/courses-list">
                                <span className="basePath">Courses</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="basePath">Videos</span>
                        </li>
                        <li className="breadcrumb-item active currentPath">Add</li>
                    </ol>
                    <h1 className="page-header">Add video</h1>

                    <div className="row">
                        <div className="col-xl-12 p-5">
                            <div
                                className="card "
                                style={{
                                    height: "auto",
                                    padding: "20px",
                                    borderRadius: "20px",
                                }}
                            >

                                <form onSubmit={(e) => submitHandler(e)} className="profileForm row" >
                                    {/* <div className="col-sm-6 mb-3">
                                        <label for="exampleInputEmail1" className="form-label"> Video name : </label>
                                        <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter title here.." name="name"
                                            value={videoData.name} onChange={InputEvent} />
                                        <div className="text-danger">{errors.name_err}</div>
                                    </div> */}

                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputEmail1" className="form-label"> Guru name : </label>
                                        <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter title here.." name="guruName"
                                            value={guruName} onChange={(e) => setGuruName(e.target.value)} />
                                        <div className="text-danger">{errors.guruName_err}</div>
                                    </div>
                                    <div className='col-sm-6 mb-3'>
                                        <label for="exampleInputImage" className="form-label">&nbsp;</label>
                                        <div className="ml-0 mt-1">
                                            <button type='button' className='btn btn-success mx-2' onClick={handleShow}>
                                                Add More Videos
                                            </button>
                                        </div>
                                    </div>

                                    <div className='col-sm-12 mb-3'>
                                        <label for="exampleInputEmail1" className="form-label"> Videos : </label>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Video</th>
                                                    <th>Thumbnail Image</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {videos.length > 0 && videos.map(elem =>
                                                    <tr>
                                                        <td>{elem.name}</td>
                                                        <td>{elem.video_url ? 'Uploaded' : 'Not Uploaded'}</td>
                                                        <td>{elem.image ? 'Uploaded' : 'Not Uploaded'}</td>
                                                        <td><i className='fa fa-trash' onClick={() => handleDelete(elem.video_url)} /></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                        {videos?.length === 0 ? (
                                            <div className="row d-flex justify-content-center w-100">
                                                <div className="mx-auto d-flex justify-content-center w-100">
                                                    <img
                                                        src="./assets/img/icon/no-location.png"
                                                        className="form-img__img-preview"
                                                        style={{ width: "100px", height: "100px" }}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>

                                    {/* <div className="col-sm-6 mb-3">
                                        <label for="exampleInputImage" className="form-label">Video: </label>
                                        <input
                                            id='video_player'
                                            type="file"
                                            className="form-control imgInput ml-0 mt-1"
                                            onChange={handleVideo}
                                        />
                                        {video.src && (
                                            <div>
                                                <video style={{ width: "auto", width: "50%", marginTop: "20px", objectFit: "cover", borderRadius: '10px' }} src={video.src} controls />
                                            </div>
                                        )}

                                        <div className="text-danger">{errors.video_err}</div>
                                    </div> */}

                                    {/* <div className="col-sm-6 mb-3">
                                        <label for="exampleInputImage" className="form-label">Thumbnail image: </label>
                                        <input
                                            type="file"
                                            className="form-control imgInput ml-0 mt-1"
                                            id="exampleInputImage"
                                            onChange={handleImg}
                                            accept="image/png, image/gif, image/jpeg"
                                        />
                                        {img.src !== "" ? (
                                            <img
                                                src={img.src}
                                                className="form-img__img-preview mt-1"
                                                style={{ width: "84px", height: "84px" }}
                                                alt="imgs"
                                            />
                                        ) : (
                                            ""
                                        )}
                                        <div className="text-danger">{errors.img_err}</div>
                                    </div> */}
                                    {/* <div className="col-sm-6 mb-3">
                                        <label for="exampleInputImage" className="form-label">&nbsp;</label>
                                    </div> */}

                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputImage" className="form-label">&nbsp;</label>
                                        <div className="ml-0 mt-1">
                                            <button type="submit" className="btn btn-success mr-2" disabled={disable}>
                                                {disable ? "Processing..." : "Submit"}</button>
                                            <button type="reset" className="btn btn-dark" value="Reset"
                                                onClick={(e) => {
                                                    setImg({ src: "", alt: "" })
                                                    setIsPicUpload(false);
                                                    setIsVideoUpload(false);
                                                    // setVideo({ src: "", alt: "" });
                                                    setChange(!change);
                                                }}
                                            >Reset</button>
                                            {/* <button type='button' className='btn btn-success mx-2' onClick={handleShow}>
                                                Add More Videos
                                            </button> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
            </div>

            {/* modal */}



            <Modal show={show} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="profileForm" >
                        <div className='row'>
                            <div className="col-sm-12 mb-3">
                                <label for="exampleInputEmail1" className="form-label"> Video name : </label>
                                <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter title here.." name="videoName"
                                    value={videoName}
                                    onChange={(e) => setVideoName(e.target.value)}
                                />
                                <div className="text-danger">{errors.name_err}</div>
                            </div>

                            <div className="col-sm-12 mb-3">
                                <label for="exampleInputImage" className="form-label">Video: </label>
                                <input
                                    id='video_player'
                                    type="file"
                                    className="form-control imgInput ml-0 mt-1"
                                    onChange={handleVideo}
                                    ref={videoRef}
                                />
                                {videoURL && (
                                    <div>
                                        <video style={{ width: "auto", width: "50%", marginTop: "20px", objectFit: "cover", borderRadius: '10px' }} src={videoURL} controls />
                                    </div>
                                )}
                                <div className="text-danger">{errors.video_err}</div>
                            </div>

                            <div className="col-sm-12 mb-3">
                                <label for="exampleInputImage" className="form-label">Thumbnail image: </label>
                                <input
                                    type="file"
                                    className="form-control imgInput ml-0 mt-1"
                                    id="exampleInputImage"
                                    onChange={handleImg}
                                    accept="image/png, image/gif, image/jpeg"
                                    ref={imgRef}
                                />
                                {pictureName !== "" ? (
                                    <img
                                        src={pictureName}
                                        className="form-img__img-preview mt-1"
                                        style={{ width: "84px", height: "84px" }}
                                        alt="imgs"
                                    />
                                ) : (
                                    ""
                                )}
                                <div className="text-danger">{errors.img_err}</div>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <button onClick={handleSaveModal} className="btn btn-success mr-2" disabled={disable || disableImg || disableVid}>
                        {disable || disableImg || disableVid ? "Processing..." : "Submit"}</button>
                    <button type="reset" className="btn btn-dark" value="Reset"
                        onClick={(e) => {
                            setImg({ src: "", alt: "" })
                            setIsPicUpload(false);
                            setIsVideoUpload(false);
                            setVideoURL('')
                            setPictureName('')
                            setVideoName('')
                            videoRef.current.value = "";
                            imgRef.current.value = "";
                            // setVideo({ src: "", alt: "" });
                            setChange(!change);
                        }}
                    >Reset</button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default AddVideo