import React, { useState, useEffect, useMemo } from 'react'
import Footer from '../include/Footer';
import Loader from '../include/Loader';
import Menu from '../include/Menu';
import Spinner from '../include/Spinner';
import { toast } from "react-toastify";
import { Dropdown, Table, DropdownButton, Button } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Pagination, Search } from "../Table";
import moment from 'moment';
import axios from 'axios';
import { D_URL } from "../baseurl";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

const Subscription = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [entity, setEntity] = useState([]);
  const [allEntity, setAllEntity] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [limit, setlimit] = useState(10);
  const [disable, setDisable] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState("");


  useEffect(() => {

    if (localStorage.getItem("OM_Admin_ID") == null) {
      navigate("/om-outline-admin");
      return;
    }

    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");

    if (disable == true) {
      setEntity([]);
      setAllEntity()
      getSubscription();
    }

  }, [disable])

  const subscriptionData = useMemo(() => {
    let computedSubscription = entity;

    if (search) {
      computedSubscription = computedSubscription.filter(
        (subscription) =>

          subscription.user?.toLowerCase()?.includes(search?.toLowerCase()) ||
          subscription.amount
            ?.toString()
            ?.includes(search?.toLowerCase()) ||
          moment(subscription?.started_At).format("YYYY-MM-DD hh:mm:ss a")?.includes(search?.toLowerCase()) ||
          moment(subscription?.expired_At).format("YYYY-MM-DD hh:mm:ss a")?.includes(search?.toLowerCase()) ||
          subscription.type?.toLowerCase()?.includes(search?.toLowerCase()) ||

          subscription.status === "succeeded" && "".includes(search?.toLowerCase())


      )
    }
    setTotalItems(computedSubscription.length)


    // Apply month filter
    // if (selectedMonth) {
    //   computedSubscription = allEntity.filter((item) =>
    //     moment(item.started_At)
    //   );
    // }

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedSubscription = computedSubscription.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedSubscription?.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );

  }, [currentPage, search, sorting, limit, entity, selectedMonth])


  // const handleMonthFilterChange = (event) => {
  //   setSelectedMonth(event.target.value);
  // };


  const getSubscription = () => {
    const myurl = `${D_URL}/get-list`;
    const fd = new FormData();

    fd.append("page", currentPage);
    axios({
      method: "post",
      url: myurl,
      data: fd,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('OM_Admin_ID') },
    })
      .then(async (response) => {
        console?.log("@@", response?.data.data);
        if (response?.data?.status) {
          let data = await response?.data?.data?.map((e, i) => {
            return { ...e, sr_no: i + 1 };
          });
          setEntity(data);
          setAllEntity(data)
          setAllData(data);
          setDisable(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        console?.log("Errors", error);
      });
  }

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [allData, setAllData] = useState([])

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }

  const handleMonth = (date) => {
    let filtered = allData.filter((pro) => {
      let proDate = new Date(pro.started_At)
      return (
        proDate >= date.selection.startDate &&
        proDate <= date.selection.endDate
      )
    })
    setStartDate(date.selection.startDate)
    setEndDate(date.selection.endDate)
    console.log(date.selection.startDate, "+++++++")
    setEntity(filtered)
  }

  const [showDatePicker, setShowDatePicker] = useState(false);
  const handleToggle = (isOpen) => {
    setShowDatePicker(isOpen);
  };

  let totalAmount = 0;

  entity?.forEach(item => {
    if (item.amount !== null && item.amount !== undefined) {
      totalAmount += item.amount;
    }
  });

  console.log(totalAmount);

  return (
    <div>
      <>
        <Loader />

        <div
          id="page-container"
          className="fade page-sidebar-fixed page-header-fixed"
        >
          <Menu />

          <div id="content" className="content">
            <ol className="breadcrumb float-xl-right">
              <li className="breadcrumb-item basePath ">
                <a href="javascript:;">Home</a>
              </li>
              <li className="breadcrumb-item active currentPath">
                Subscription Management
              </li>
            </ol>
            <h1 className="page-header">Subscription Management</h1>
            {disable == false ? (
              <div
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "20px",
                  marginTop: "10px",
                }}
              >
                <div className="row w-100">
                  <div className="mb-3 col-12 text-center">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-sm-3 col-12 mb-3">
                        <div className="ml-0">
                          <div className="d-flex">
                            <h5 className="mt-2 mr-1">Search: </h5>
                            <Search
                              onSearch={(value) => {
                                setSearch(value);
                                setCurrentPage(1);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
                        <div
                          style={{
                            color: "black",
                            fontSize: "12px",
                            fontWeight: "300",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          className="align-self-center"
                        >
                          <h5 className="mt-2 mr-1">Total Subscribers : {entity?.length} </h5>
                        </div>
                      </div>
                      <div className="mx-2 mt-2 mr-1">
                        <div
                          style={{
                            color: "black",
                            fontSize: "12px",
                            fontWeight: "300",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          className="align-self-center"
                        >
                          <h5>Total Earning : {parseFloat(totalAmount).toFixed(2)} </h5>
                        </div>
                      </div>

                      <div className="col d-flex justify-content-end mb-3">
                        {/* <Dropdown className='mx-3'>
                          <Dropdown.Toggle variant="primary" id="month-filter">
                            Filter by month: {selectedMonth || 'All'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setSelectedMonth("")}>All</Dropdown.Item>
                            <Dropdown.Item onClick={() => setSelectedMonth(moment().format("YYYY-MM"))}>This Month</Dropdown.Item>
                            <Dropdown.Item onClick={() => setSelectedMonth(moment().subtract(1, "months").format("YYYY-MM"))}>Last Month</Dropdown.Item>
                            <Dropdown.Item onClick={() => setSelectedMonth(moment().subtract(2, "months").format("YYYY-MM"))}>Last 3 Months</Dropdown.Item>
                            <Dropdown.Item onClick={() => setSelectedMonth(moment().subtract(5, "months").format("YYYY-MM"))}>Last 6 Months</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}

                        <Dropdown className='mx-3' onToggle={handleToggle}>
                          <Dropdown.Toggle>
                          <i className="fas fa-calendar"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {showDatePicker && (
                              <Dropdown.Item onClick={(e) => e.stopPropagation()}>
                                <DateRangePicker
                                  ranges={[selectionRange]}
                                  onChange={handleMonth}
                                />
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>

                        <div
                          style={{
                            color: "black",
                            fontSize: "12px",
                            fontWeight: "300",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          className="align-self-center"
                        >
                          <b>Rows per page :&nbsp;</b>
                        </div>
                        <div className="align-self-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="none"
                              id="dropdown-basic"
                              style={{
                                cursor: "auto",
                                backgroundColor: "white",
                                borderColor: "#d5dbe0",
                                paddingBottom: "3px",
                                paddingTop: "3px",
                              }}
                            >
                              {limit}&nbsp;<i className="fa fa-caret-down"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {limit !== 10 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(10);
                                    }}
                                  >
                                    10
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 20 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(20);
                                    }}
                                  >
                                    20
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 30 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(30);
                                    }}
                                  >
                                    30
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 50 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(50);
                                    }}
                                  >
                                    50
                                  </Dropdown.Item>
                                </>
                              ) : null}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-12">
                        <div className="table-responsive">
                          <Table striped bordered hover>
                            <thead>

                              <tr>
                                <th>Sr no</th>
                                <th>User name</th>
                                {/* <th>Description</th> */}
                                {/* <th>Subscription Plan</th> */}
                                <th>Type</th>
                                <th>Subscription Amount</th>
                                <th>Plan Started At</th>
                                <th>Plan Expired At</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {subscriptionData.map((e, i) => (
                                <tr key={e?._id}>
                                  <>
                                    <td>{e?.sr_no}</td>
                                    <td>{e?.user}</td>
                                    <td>{e?.type}</td>
                                    {/* <td>{e?.description}</td> */}
                                    {/* <td>{e?.plan}</td> */}
                                    <td>{e?.amount}</td>
                                    <td>{moment(e?.started_At).format("YYYY-MM-DD hh:mm:ss A")}</td>
                                    <td>{moment(e?.expired_At).format("YYYY-MM-DD hh:mm:ss A")}</td>
                                    <td>{e?.status === "succeeded" ? <span className="badge badge-success">Success</span> : <span   >Failed</span>}</td>
                                  </>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          {subscriptionData.length == 0 ? (
                            <div className="row d-flex justify-content-center w-100">
                              <div className="mx-auto  d-flex justify-content-center w-100">
                                <img
                                  src="./assets/img/icon/no-location.png"
                                  className="form-img__img-preview"
                                  style={{ width: "100px", height: "100px" }}
                                  alt=""
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                      style={{
                        overflowX: "auto",
                      }}
                    >
                      <Pagination
                        total={totalItems}
                        itemsPerPage={limit}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Spinner />
            )}
          </div>

          <Footer />
        </div>
      </>
    </div>
  )
}

export default Subscription