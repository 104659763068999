import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify";
import { D_URL } from "../../baseurl";
import Footer from '../../include/Footer';
import Loader from '../../include/Loader';
import Menu from '../../include/Menu';

const EditVideo = () => {

    const [change, setChange] = useState(true);
    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    const data = location.state;

    useEffect(() => {
        document.getElementById("page-loader").style.display = "none";
        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, [change]);

    const [videoData, setVideoData] = useState({

        name: location?.state?.name,
        video_id: location?.state?._id,
        guru_name: location?.state?.guru_name

    });
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);

    const [video, setVideo] = useState({ src: location?.state?.video_url, alt: '' });
    const [isVideoUpload, setIsVideoUpload] = useState(false);
    const [videoURL, setVideoURL] = useState(location?.state?.video_url);

    const handleVideo = (e) => {
        if (e?.target?.files[0]) {
            setVideo({
                src: URL.createObjectURL(e?.target?.files[0]),
                alt: e?.target?.files[0].name,
            });
            setDisable(true);
            setIsVideoUpload(true);
            const myurl = `${D_URL}/upload-video`;
            const bodyFormData = new FormData();
            bodyFormData.append('image', e?.target?.files[0]);

            axios({
                method: 'post',
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: 'Barrer ' + localStorage.getItem("OM_Admin_ID") },
            })
                .then((result) => {
                    console.log('=====', result);
                    console.log(result?.data)
                    if (result?.data?.status === true) {
                        setVideoURL(result?.data?.data?.url);
                        setDisable(false);
                    } else {
                        console.log('Error:=====');
                        toast.success(result.data.message)
                        setIsVideoUpload(false);
                        setVideoURL('');
                        setDisable(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsVideoUpload(false);
                    setVideoURL('');
                    setDisable(false);
                });
        }
    };

    const [pictureName, setPictureName] = useState(location?.state?.image);
    const [isPicUpload, setIsPicUpload] = useState(false);
    const [img, setImg] = useState({
        src: location?.state?.image,
        alt: "",
    });

    const handleImg = (e) => {
        if (e?.target?.files[0]) {
            var image = new Image();
            image.src = URL.createObjectURL(e?.target?.files[0]);
            image.onload = function () {
                if (this.width > 1000 || this.height > 1000) {
                    toast.error('Image should be under 1000 * 1000 resolution')
                } else {
                    setImg({
                        src: URL.createObjectURL(e?.target?.files[0]),
                        alt: e?.target?.files[0].name,
                    });
                    setDisable(true);
                    setIsPicUpload(true);
                    const myurl = `${process.env.REACT_APP_BASE_URL}api/v1/upload-image`;
                    var bodyFormData = new FormData();
                    bodyFormData.append("image", e?.target?.files[0]);

                    axios({
                        method: "post",
                        url: myurl,
                        data: bodyFormData,
                    })

                        .then((result) => {
                            console.log("=====", result);

                            if (result?.data?.status == true) {
                                //console.log('Success:=====',result?.data?.data?.filepath_url);
                                setPictureName(result?.data?.data?.url);
                                setDisable(false);
                            } else {
                                console.log("Error:=====");
                                // console.log('Error:=====', result?.data);
                                setIsPicUpload(false);
                                setPictureName("");
                                setDisable(false);
                            }
                        })
                        .catch((error) => {
                            // console.log(error);
                            //console.log('Errors', error);
                            setIsPicUpload(false);
                            setPictureName("");
                            setDisable(false);
                        });
                }
            }
        }
    };

    const InputEvent = (e) => {
        const newVideoData = { ...videoData };
        newVideoData[e.target.name] = e.target.value;
        setVideoData(newVideoData);
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);

            const { name } = videoData;
            const myurl = `${D_URL}/edit-course-video`;
            var bodyFormData = new URLSearchParams();
            bodyFormData.append("name", videoData.name);
            bodyFormData.append("video_id", data._id);
            bodyFormData.append("video_url", videoURL);
            bodyFormData.append("image", pictureName);
            bodyFormData.append("guru_name", videoData.guru_name);
            bodyFormData.append("video_key", data.video_key )

            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: 'Barrer ' + localStorage.getItem("OM_Admin_ID") },
            })
                .then((response) => {
                    console.log(response.data)
                    if (response?.data?.status === true) {

                        setDisable(false);
                        console.log("===SUCCESSFUL===", response?.data);
                        toast.success(response?.data?.message);
                        navigate('/courses-list');

                    } else {
                        console.log("===Error===");
                        // toast.error(response?.data?.message)
                        //console.log('===Error===', response?.data);
                        setDisable(false);
                        setVideoURL({});
                        setIsVideoUpload(false);
                    }
                })
                .catch((error) => {
                    //console.log('Errors', error);
                    // console.log("Errors");
                    toast.error("Something went wrong");
                    setVideoURL({});
                    setDisable(false);
                    setIsVideoUpload(false);
                });
        }
        setChange(!change);
    };

    const validate = () => {
        let input = videoData;

        let errors = {};
        let isValid = true;


        if (!input["name"]) {
            isValid = false;
            errors["name_err"] = "Please enter name";
        }
        if (!input["guru_name"]) {
            isValid = false;
            errors["guru_name_err"] = "Please enter guru";
        }
        if (!video.src) {
            errors["video_err"] = "Please select a video file";
        }
        if (!setIsPicUpload || pictureName === "") {
            isValid = false;
            errors["img_err"] = "Please select image";
        }

        setErrors(errors);
        return isValid;
    }

    return (
        <>
            <Loader />

            <div
                id="page-container"
                className="fade page-sidebar-fixed page-header-fixed"
            >
                <Menu />

                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard">
                                <span className="basePath">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink to="/courses-list">
                                <span className="basePath">Courses</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="basePath">Videos</span>
                        </li>
                        <li className="breadcrumb-item active currentPath">Edit</li>
                    </ol>
                    <h1 className="page-header">Edit video</h1>

                    <div className="row">
                        <div className="col-xl-12 p-5">
                            <div
                                className="card "
                                style={{
                                    height: "auto",
                                    padding: "20px",
                                    borderRadius: "20px",
                                }}
                            >

                                <form onSubmit={(e) => submitHandler(e)} className="profileForm row" >
                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputEmail1" className="form-label"> Video name : </label>
                                        <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter title here.." name="name"
                                            value={videoData.name} onChange={InputEvent} />
                                        <div className="text-danger">{errors.name_err}</div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputEmail1" className="form-label"> Guru name : </label>
                                        <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter title here.." name="guru_name"
                                            value={videoData.guru_name} onChange={InputEvent} />
                                        <div className="text-danger">{errors.name_err}</div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputImage" className="form-label">Video: </label>
                                        <input
                                            type="file"
                                            className="form-control imgInput ml-0 mt-1"
                                            onChange={handleVideo}
                                        />
                                        {video.src && (
                                            <div>
                                                <video style={{ width: "auto", width: "50%", marginTop: "20px", objectFit: "cover", borderRadius: '10px' }}
                                                    alt='video' src={video.src} controls />
                                            </div>
                                        )}

                                        <div className="text-danger">{errors.video_err}</div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputImage" className="form-label">Thumbnail image: </label>
                                        <input
                                            type="file"
                                            className="form-control imgInput ml-0 mt-1"
                                            id="exampleInputImage"
                                            onChange={handleImg}
                                            accept="image/png, image/gif, image/jpeg"
                                        />
                                        {img.src != "" ? (
                                            <img
                                                src={img.src}
                                                className="form-img__img-preview mt-1"
                                                style={{ width: "84px", height: "84px" }}
                                                alt="imgs"
                                            />
                                        ) : (
                                            ""
                                        )}
                                        <div className="text-danger">{errors.img_err}</div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                        <label for="exampleInputImage" className="form-label">&nbsp;</label>
                                        <div className="ml-0 mt-1">
                                            <button type="submit" className="btn btn-success mr-2" disabled={disable}>
                                                {disable ? "Processing..." : "Update"}</button>
                                            {/* <button type="reset" className="btn btn-dark" value="Reset"
                                                onClick={(e) => {
                                                    setImg({ src: "", alt: "" })
                                                    setIsPicUpload(false);
                                                    setIsVideoUpload(false);
                                                    setVideo({ src: "", alt: "" });
                                                    setChange(!change);
                                                }}
                                            >Reset</button> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default EditVideo