import React, { useState, useEffect, useMemo } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { D_URL } from "../baseurl";
import axios from "axios";
import { toast } from "react-toastify";
import Menu from '../include/Menu';
import Spinner from '../include/Spinner';
import { Dropdown, Table } from "react-bootstrap";
import { Pagination, Search } from '../Table';
import Loader from '../include/Loader';
import Footer from '../include/Footer';
import Swal from "sweetalert2";

const PastVideo = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [eventId, setEventId] = useState({
        event_id: location.state._id
    })
    const [entity, setEntity] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);
    const [isApprove, setIsApprove] = useState(false)

    const userHeader = [
        {
            name: "Sr. No.",
            field: "id",
            sortable: false,
        },
        {
            name: "Videos",
            // field: "",
            sortable: false,
        },
        {
            name: "Actions",
            // field: "",
            sortable: false,
        },
    ];

    useEffect(() => {
        if (localStorage.getItem("OM_Admin_ID") == null) {
            navigate("/om-outline-admin");
            return;
        }
        document.getElementById("page-loader").style.display = "none";
        var element = document.getElementById("page-container");
        element.classList.add("show");
        if (disable == true) {
            setEntity([]);
            getPastVideo();
        }
    }, [disable]);

    const ViewPast = useMemo(() => {
        let computedEvents = entity;

        if (search) {
            computedEvents = computedEvents.filter(
                (events) =>

                    events.program_title?.toLowerCase()?.includes(search?.toLowerCase())

            );
        }
        setTotalItems(computedEvents.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedEvents = computedEvents.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedEvents?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [currentPage, search, sorting, limit, entity]);


    const getPastVideo = () => {
        const myurl = `${D_URL}/get-recording`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("room_id", eventId.event_id);
        // bodyFormData.append("page", currentPage);

        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('OM_Admin_ID') },
        })
            .then(async (response) => {
                console?.log("@@", response?.data);
                if (response?.data?.status) {
                    let data = await response?.data?.data.map((e, i) => {
                        return { ...e, sr_no: i + 1 };
                    });
                    const item = data.find((item) => item.status === true);
                    if (item !== undefined) {
                        setIsApprove(true)
                    }
                    console.log(item, "item")
                    setEntity(data);

                    setDisable(false);
                } else {
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
                console?.log("Errors", error);
            });
    }

    const deleteGuru = (id) => {
        Swal.fire({
            title: "Delete",
            text: `Are you sure to want to delete`,
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then(async (res) => {
            if (res.isConfirmed) {
                const myurl = `${D_URL}/delete-recording`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("recording_id", id);
                axios({
                    method: "post",
                    url: myurl,
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('OM_Admin_ID') },
                })
                    .then(async (response) => {
                        console?.log("@@", response?.data);
                        if (response?.data?.status) {
                            setDisable(true);
                            toast.success(response?.data?.message);

                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };

    const approveGuru = (id) => {
        Swal.fire({
            title: "Approve",
            text: `Are you sure to want to approve`,
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Approve',
            showCancelButton: true,
            confirmButtonText: "Approve",
        }).then(async (res) => {
            if (res.isConfirmed) {
                const myurl = `${D_URL}/approve-recording`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("recording_id", id);
                axios({
                    method: "post",
                    url: myurl,
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('OM_Admin_ID') },
                })
                    .then((response) => {
                        console?.log("@@", response?.data);
                        if (response?.data?.status) {
                            setDisable(true);
                            toast.success(response?.data?.message);
                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Something went wrong");
                        console?.log("Errors", error);
                    });
            }
        });
    };

    return (
        <>
            <Loader />

            <div
                id="page-container"
                className="fade page-sidebar-fixed page-header-fixed"
            >
                <Menu />

                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item basePath ">
                            <a href="javascript:;">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink to="/classes-list">
                                <span className="basePath">Classes Management</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item active currentPath">
                            Past Recording
                        </li>
                    </ol>
                    <h1 className="page-header">Past Recording Management</h1>
                    {disable == false ? (
                        <div
                            style={{
                                backgroundColor: "white",
                                padding: "20px",
                                borderRadius: "20px",
                                marginTop: "10px",
                            }}
                        >
                            <div className="row w-100">
                                <div className="mb-3 col-12 text-center">
                                    <NavLink to="/add-classes" className="btn btn-primary mb-3" style={{ float: "right", float: "right", padding: "3px 20px", fontSize: "16px" }}>Add Event</NavLink>
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-3 col-sm-3 col-12 mb-3">
                                            <div className="ml-0">
                                                <div className="d-flex">
                                                    <h5 className="mt-2 mr-1">Search: </h5>
                                                    <Search
                                                        onSearch={(value) => {
                                                            setSearch(value);
                                                            setCurrentPage(1);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "12px",
                                                    fontWeight: "300",
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                }}
                                                className="align-self-center"
                                            >
                                                <h5 className="mt-2 mr-1">Total Videos : {entity?.length} </h5>
                                            </div>
                                        </div>
                                        <div className="col d-flex justify-content-end mb-3">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "12px",
                                                    fontWeight: "300",
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                }}
                                                className="align-self-center"
                                            >
                                                <b>Rows per page :&nbsp;</b>
                                            </div>
                                            <div className="align-self-center">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="none"
                                                        id="dropdown-basic"
                                                        style={{
                                                            cursor: "auto",
                                                            backgroundColor: "white",
                                                            borderColor: "#d5dbe0",
                                                            paddingBottom: "3px",
                                                            paddingTop: "3px",
                                                        }}
                                                    >
                                                        {limit}&nbsp;<i className="fa fa-caret-down"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {limit !== 10 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(10);
                                                                    }}
                                                                >
                                                                    10
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 20 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(20);
                                                                    }}
                                                                >
                                                                    20
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 30 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(30);
                                                                    }}
                                                                >
                                                                    30
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 50 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(50);
                                                                    }}
                                                                >
                                                                    50
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <Table striped bordered hover>
                                                    <thead>
                                                        {/* <TableHeader
                                headers={userHeader}
                                onSorting={(field, order) =>
                                  setSorting({ field, order })
                                }
                              /> */}
                                                        <tr>
                                                            <th>Sr no</th>
                                                            <th>Videos</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ViewPast.map((e, i) => (
                                                            <tr>
                                                                <>
                                                                    <td>{e?.sr_no}</td>
                                                                    <td>
                                                                        {<video src={e?.url} alt='' className='mx-2' style={{ width: "auto", height: "100px", objectFit: "cover", borderRadius: '10px' }} controls />}
                                                                    </td>
                                                                    <td>{e?.status === true ? <span className="badge badge-success">Approve</span> : <span className="badge badge-danger">UnApprove</span>}</td>

                                                                    <td>
                                                                        {(isApprove === true) ? '' :
                                                                            <><span className="text text-success" style={{ cursor: "pointer" }} onClick={() => approveGuru(e.id)}><u>Approve</u></span><br /></>
                                                                        }
                                                                        {/* <span className="text text-success" style={{ cursor: "pointer" }} onClick={() => approveGuru(e.id)}><u>Approve</u></span><br /> */}
                                                                        <span className="text text-secondary" style={{ cursor: "pointer" }} onClick={() => deleteGuru(e.id)}><u>Delete</u></span>
                                                                    </td>
                                                                </>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                {ViewPast.length == 0 ? (
                                                    <div className="row d-flex justify-content-center w-100">
                                                        <div className="mx-auto  d-flex justify-content-center w-100">
                                                            <img
                                                                src="./assets/img/icon/no-location.png"
                                                                className="form-img__img-preview"
                                                                style={{ width: "100px", height: "100px" }}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                                        style={{
                                            overflowX: "auto",
                                        }}
                                    >
                                        <Pagination
                                            total={totalItems}
                                            itemsPerPage={limit}
                                            currentPage={currentPage}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </div>

                <Footer />
            </div>
        </>
    )
}

export default PastVideo