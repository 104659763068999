import React, { useEffect, useState } from 'react'
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from 'axios';
import Footer from '../include/Footer';
import Menu from '../include/Menu';
import Loader from '../include/Loader';
import { D_URL } from "../baseurl";


const SubscriptionPlan = () => {

    const [change, setChange] = useState(true);
    const navigate = useNavigate();
    const [plan, setPlan] = useState({});
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);


    useEffect(() => {
        document.getElementById("page-loader").style.display = "none";
        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, [change]);

    const InputEvent = (e) => {
        const newplanData = { ...plan };
        newplanData[e.target.name] = e.target.value;
        setPlan(newplanData);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);

            const myurl = `${D_URL}/add-subscription-plan`;
            var bodyFormData = new URLSearchParams();
            bodyFormData.append("plan_name", plan.name);
            bodyFormData.append("description", plan.description);
            bodyFormData.append("duration", plan.duration);
            bodyFormData.append("amount", plan.amount);
            bodyFormData.append("type", plan.type);

            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: 'Barrer ' + localStorage.getItem("OM_Admin_ID") },
            }).then((response) => {
                if (response?.data?.status == true) {

                    setDisable(false);
                    console.log("===SUCCESSFUL===", response?.data);
                    toast.success("Plan added successfully");
                    navigate('/subscription-plan-list');

                } else {
                    console.log("===Error===");
                    //console.log('===Error===', response?.data);
                    setDisable(false);
                }
            })
                .catch((error) => {
                    //console.log('Errors', error);
                    console.log("Errors");
                    toast.error("Something went wrong");
                    setDisable(false);
                });
        }
    }


    const validate = () => {
        let input = plan;

        let errors = {};
        let isValid = true;

        if (!input["name"]) {
            isValid = false;
            errors["name_err"] = "Please enter Plane name";
        }
        if (!input["type"] || input["type"] == "type") {
            isValid = false;
            errors["type_err"] = "Please select type";
        }
        if (!input["description"]) {
            isValid = false;
            errors["description_err"] = "Please enter description";
        }

        if (!input["amount"]) {
            isValid = false;
            errors["amount_err"] = "Please enter plan amount";
        }

        if (!input["duration"] || input["duration"] == "duration") {
            isValid = false;
            errors["duration_err"] = "Please select plan duration";
        }

        setErrors(errors);
        return isValid;
    };

    return (
        <div>
            <>
                <Loader />

                <div
                    id="page-container"
                    className="fade page-sidebar-fixed page-header-fixed"
                >
                    <Menu />

                    <div id="content" className="content">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <NavLink to="/dashboard">
                                    <span className="basePath">Home</span>
                                </NavLink>
                            </li>
                            <li className="breadcrumb-item">
                                <NavLink to="/subscription-plan-list">
                                    <span className="basePath">Subscription Plan</span>
                                </NavLink>
                            </li>
                            <li className="breadcrumb-item active currentPath">Add Subscription Plan</li>
                        </ol>
                        <h1 className="page-header">Add Subscription Plan</h1>

                        <div className="row">
                            <div className="col-xl-12 p-5">
                                <div
                                    className="card "
                                    style={{
                                        height: "auto",
                                        padding: "20px",
                                        borderRadius: "20px",
                                    }}
                                >

                                    <form onSubmit={(e) => submitHandler(e)} className="profileForm row" >
                                        <div className="col-sm-6 mb-3">
                                            <label for="exampleInputEmail1" className="form-label"> Plan Name : </label>
                                            <input type="text" className="form-control ml-0" id="exampleInputName" placeholder="Enter Plan name here.." name="name"
                                                value={plan.name} onChange={InputEvent} />
                                            <div className="text-danger">{errors.name_err}</div>
                                        </div>

                                        <div className="col-sm-6 mb-3">
                                            <label for="exampleInputEmail1" className="form-label">Amount : </label>
                                            <input type="number" min="0"
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 38 || e.keyCode === 40) {
                                                        e.preventDefault();
                                                    }
                                                }} className="form-control ml-0" id="exampleInputName" placeholder="Enter Plan amount here.." name="amount"
                                                value={plan.amount} onChange={InputEvent} />
                                            <div className="text-danger">{errors.amount_err}</div>
                                        </div>

                                        <div className="col-sm-6 mb-3">
                                            <label for="exampleInputEmail1" className="form-label"> Description : </label>
                                            <textarea className="form-control ml-0" id="exampleInputName" placeholder="Enter description here.." name="description"
                                                onChange={InputEvent} value={plan.description} rows="3"></textarea>

                                            <div className="text-danger">{errors.description_err}</div>
                                        </div>

                                        <div className="col-sm-6 mb-3">
                                            <label for="exampleInputEmail1" className="form-label"> Select Type: </label>
                                            <select className="form-control ml-0" name="type"
                                                value={plan.type} onChange={InputEvent}>
                                                <option value="type">Select Type</option>
                                                <option value="School & company">School & Company</option>
                                                <option value="customer">Customer</option>
                                            </select>
                                            <div className="text-danger">{errors.type_err}</div>
                                        </div>

                                        <div className="col-sm-6 mb-3">
                                            <label for="exampleInputEmail1" className="form-label"> Select Plan duration: </label>
                                            <select className="form-control ml-0" name="duration"
                                                value={plan.duration} onChange={InputEvent}>
                                                <option value="duration">Select Plan duration</option>
                                                <option value="1 month">1 month</option>
                                                <option value="3 month">3 month</option>
                                                <option value="6 month">6 month</option>
                                                <option value="1 year">1 year</option>
                                            </select>
                                            <div className="text-danger">{errors.duration_err}</div>
                                        </div>

                                        <div className="col-sm-6 mb-3">
                                            <label for="exampleInputImage" className="form-label">&nbsp;</label>
                                            <div className="ml-0 mt-1">
                                                <button type="submit" className="btn  btn-success mr-2" disabled={disable}>
                                                    {disable ? "Processing..." : "Submit"}</button>
                                                <button type="reset" className="btn btn-dark" value="Reset"
                                                    onClick={(e) => {
                                                        setChange(!change);
                                                    }}
                                                >Reset</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </>
        </div>
    )
}

export default SubscriptionPlan