import React from 'react';

export default function ShowImage(props) {
  return (
    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 restaurantDetail">
      <span className="restaurant_heading">{props.title}</span>
      <div className="restaurantImage ml-0">
        {props?.data?.match('undefined') != 'undefined' ? (
          props?.data != '' ? (
            <>
              <a href={props.url} target="_blank">
                <img
                  className="restaurant_Image ml-0"
                  src={props.url}
                  alt={props.alt}
                />
              </a>
            </>
          ) : (
            <>
              <img
                src={props.dummyUrl}
                alt={props.alt}
                style={{
                  width: '100px',
                  height: '100px',
                  margin: '10px',
                }}
              />
            </>
          )
        ) : (
          <>
            <img
              src={props.dummyUrl}
              alt={props.alt}
              style={{
                width: '100px',
                height: '100px',
                margin: '10px',
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
