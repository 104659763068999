import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Footer from "../include/Footer";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../include/Spinner";
import { D_URL } from "../baseurl";

function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [entity, setEntity] = useState({});
  const [disable, setDisable] = useState(true);

  const countData = () => {
    const myurl = `${D_URL}/dashboard-widget`;
    axios({
      method: "post",
      url: myurl,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: 'Barrer ' + localStorage.getItem("OM_Admin_ID") },
    })
      .then(async (response) => {
        console.log("@@", response?.data?.data[0]);
        if (response?.data?.status) {
          await setEntity(response?.data?.data);
          console.log(response?.data.data.Subscriptio, "###")
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        console.log("Errors", error);
      });
  };
  console.log("111", entity);
  useEffect(() => {
    if (localStorage.getItem("OM_Admin_ID") == null) {
      navigate("/om-outline-admin");
      return;
    }
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    if (disable == true) {
      setEntity([]);
      countData();
    }
  }, [disable]);


  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              <a href="javascript:;">Home</a>
            </li>
            <li className="breadcrumb-item active currentPath">Dashboard</li>
          </ol>
          <h1 className="page-header">Dashboard </h1>

          {/* ====================================== */}
          {console.log("dfdfdf", entity)}
          {entity?.length !== 0 ? (
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-blue">
                  <div className="stats-icon">
                    <i className="fa fa-users"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Users</h4>
                    <p>{entity?.users}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/user-list">
                      View Detail{" "}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-info">
                  <div className="stats-icon">
                    {/* <i className="fa fa-users"></i> */}
                    <i className="fa fa-user-tie"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Guides</h4>
                    <p>{entity?.gurus}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/guide-list">
                      View Detail{" "}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-danger">
                  <div className="stats-icon">
                    {/* <i className="fa fa-users"></i> */}
                    <i className="fa fa-tasks"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Classes</h4>
                    <p>{entity?.events}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/classes-list">
                      View Detail{" "}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-success">
                  <div className="stats-icon">
                    {/* <i className="fa fa-users"></i> */}
                    <i className="fa fa-tasks"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Groups</h4>
                    <p>{entity?.schoolCompanies}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/group-list">
                      View Detail{" "}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats" style={{ backgroundColor: "#9080ff" }}>
                  <div className="stats-icon">
                    {/* <i className="fa fa-users"></i> */}
                    <i className="fas fa-dollar-sign"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Subscription Income</h4>
                    <p>  {entity?.Subscription && (
                      <>
                        {entity.Subscription.map((item) => (
                          <p key={item}>{item.sum}</p>
                        ))}
                      </>
                    )}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/subscription-list">
                      View Detail{" "}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>

            </div>


          ) : (
            <Spinner />
          )}

          {/* ====================================== */}
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
